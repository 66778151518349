import { useAppContext } from "../../context/appContext";
import { TextArea } from "../workspace";

const AdminWorkDesc = () => {
  const { defaults, handleChange, saveRecord, isLoading } = useAppContext();
  const handleTextChange = (index, newText) => {
    const baseObject = defaults;
    const name = "defaults.defaultWorkDescs";
    const value = defaults.defaultWorkDescs.map((description, i) => {
      return i === index ? { ...description, text: newText } : description;
    });
    handleChange({ baseObject, name, value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // iterate through all default descriptions and save each one
    defaults.defaultWorkDescs.forEach((description) => {
      saveRecord(
        `/defaults/update-work-desc/${description.id}`,
        description,
        "Default work descriptions"
      );
    });
  };
  return (
    <div className="group whole">
      <form onSubmit={handleSubmit} className="form">
        <h3>Work Descriptions</h3>
        <div className="form-center">
          <button className="btn btn-block" type="submit" disabled={isLoading}>
            Save
          </button>
          <div className="whole">
            {defaults.defaultWorkDescs?.map(({ type, text }, index) => {
              return (
                <TextArea
                  key={index}
                  name={type}
                  value={text || ""}
                  handleChange={(e) => handleTextChange(index, e.target.value)}
                />
              );
            })}
          </div>
          <button className="btn btn-block" type="submit" disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
export default AdminWorkDesc;
