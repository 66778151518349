import { useState } from "react";
import { useAppContext } from "../context/appContext";
import {
  Checkbox,
  Input,
  Display,
  TextArea,
  BasicInlineRadio,
} from "./workspace";
import { FaTimes } from "react-icons/fa";
import { iterateName } from "../utils/fileNames";

function EmailReportForm() {
  const [renameFile, setRenameFile] = useState(0);
  const [newName, setNewName] = useState("");

  const {
    job,
    isLoading,
    processReport,
    files,
    email,
    report,
    calendar,
    handleChange,
    handleChanges,
    toggleReportForm,
    saveRecord,
    user,
    displayAlert,
  } = useAppContext();

  const {
    emailBody,
    emailSubject,
    emailReceivers,
    sendEmail,
    acraliteWarranty,
    veluxWarranty,
    freeliteWarranty,
    paymentLink,
  } = email;
  const { type, status, createReport, reportName, reportDate } = report;
  const { eventTitle, eventAttendees, eventDescription, sendEvent } = calendar;

  const clientBasePath =
    process.env.NODE_ENV === "production"
      ? "http://jms.freelitetx.com"
      : "http://localhost:3000";
  const pdfString = `${clientBasePath}/reports/${job.id}?reportName=${reportName}&reportDate=${reportDate}&userMode=${user.user_mode}`;
  const createPDFText = type.includes("Invoice")
    ? `Create ${type} PDF and bill customer`
    : `Create ${type} PDF`;

  const findDuplicateFileName = (testName) => {
    return files.find((file) => file.name.includes(testName));
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (renameFile === 0) setNewName(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (createReport && !newName) {
      displayAlert("Please give a file name for the PDF.");
      return;
    }
    // update job status if a customer is invoiced
    let job_status_id;
    if (createReport && type.includes("Invoice")) {
      job_status_id = 7; // 8 - accounts receivable
      handleChanges({
        baseName: "job",
        baseObject: { ...job },
        newValues: { job_status_id },
      });
      saveRecord(
        `/core-job/update/${job.id}`,
        { ...job, job_status_id },
        "job"
      );
    }
    // create pdf and email if desired or add calendar event
    // ...also saves the appointment with any created id
    await processReport({ reportName: newName });
    // close form
    toggleReportForm();
  };

  const handleInput = (e, baseObj) => {
    handleChange({
      baseObject: { ...baseObj },
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handlePaymentLink = (e) => {
    const link = e.target.value;
    const parseBody = /([^]*?)(?=<a|Thank)(<a.*a>\n\n)?([^]*)/;
    const [, eBody, oldLink, eSignOff] = email.emailBody.match(parseBody);
    const paymentText = link
      ? `<a href="${link}">Click here for payment.</a>\n\n`
      : "";
    // console.log("fullBody:", fullBody);
    console.log("eBody:", eBody);
    console.log("eSignOff:", eSignOff);
    console.log("paymentText:", paymentText);
    const body = `${eBody}${paymentText}${eSignOff}`;
    // change email body and payment link
    handleChanges({
      baseName: "email",
      baseObject: { ...email },
      newValues: { emailBody: body, paymentLink: link },
    });
  };

  const handleRadio = (e) => {
    const value = Number(e.target.value);
    console.log("value:", value);
    setRenameFile(value);
    // Reset the file name
    if (value === 1) {
      setNewName(reportName);
    } else {
      const nextName = iterateName(reportName, files);
      setNewName(nextName);
    }
  };

  const handleCheck = (e, baseObj) => {
    const name = e.target.name;
    const value = e.target.checked;
    handleChange({
      baseObject: { ...baseObj },
      name,
      value,
    });
    if (name === "report.createReport" && value === true) {
      const newFileName = findDuplicateFileName(reportName)
        ? iterateName(reportName, files)
        : reportName;
      setNewName(newFileName);
    }
  };

  const handleCancel = () => {
    setNewName("");
    toggleReportForm();
  };

  return (
    <form className="form email-report-form">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={handleCancel}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <div className="">
          <h3>Complete processing of {type}?</h3>
          {/* Conditionally display pending job status for completed work appointments */}
          {type.includes("Invoice") && (
            <>
              {createReport ? (
                <Display
                  name="Pending Job Status"
                  value={"Accounts Receivable"}
                />
              ) : (
                <Display name="Pending Job Status" value={"Ready to Bill"} />
              )}
            </>
          )}
        </div>
      </div>
      {/* Display PDF and Email Options for completed Appointment */}
      {status?.toString() === "2" && (
        <div className="whole">
          {/* control to create pdf or not */}
          <div className="checkbox">
            <Checkbox
              type="checkbox"
              name="report.createReport"
              labelText={createPDFText}
              value={"on"}
              checked={createReport}
              handleChange={(e) => handleCheck(e, { ...report })}
            />
          </div>
          {/* show file preview link */}
          {createReport && (
            <div className="form-row">
              <a href={pdfString} target="_blank" rel="noreferrer noopener">
                Preview PDF
              </a>
            </div>
          )}
          {/* handle file name conflicts */}
          {createReport &&
            (findDuplicateFileName(reportName) ? (
              <div className="form-row flex-parent">
                <div className="whole">
                  A PDF with the name <b>{reportName}</b> already exists.
                </div>
                <div className="whole checkbox">
                  <BasicInlineRadio
                    optionNames={["renameFile", "replaceFile"]}
                    labels={["rename file", "replace file"]}
                    value={renameFile}
                    handleChange={handleRadio}
                  />
                </div>
                <div className="whole">
                  <Input
                    type="text"
                    name="report.reportName"
                    labelText="PDF Name:"
                    value={newName || ""}
                    handleChange={(e) => handleNameChange(e)}
                  />
                </div>
              </div>
            ) : (
              <div className="form-row whole">
                <Input
                  type="text"
                  name="report.reportName"
                  labelText="PDF Name:"
                  value={newName || reportName || ""}
                  handleChange={(e) => setNewName(e.target.value)}
                />
              </div>
            ))}
          {/* control to send email or not */}
          {createReport && (
            <div className="checkbox">
              <Checkbox
                type="checkbox"
                name="email.sendEmail"
                labelText="Send Email"
                value={"on"}
                checked={sendEmail}
                handleChange={(e) => handleCheck(e, { ...email })}
              />
            </div>
          )}
        </div>
      )}
      {status?.toString() === "1" && (
        <div className="whole checkbox">
          {/* Display Calendar Options for pending appointment */}
          <Checkbox
            type="checkbox"
            name="calendar.sendEvent"
            labelText={`Create/Update Calendar Appointment`}
            value={"on"}
            checked={sendEvent}
            handleChange={(e) => handleCheck(e, { ...calendar })}
          />
        </div>
      )}
      {/* email text */}
      <div className="whole">
        {createReport &&
          sendEmail &&
          status?.toString() === "2" && ( // only display if send email box is checked
            <div>
              <Input
                type="text"
                name="email.emailReceivers"
                labelText="To:"
                value={emailReceivers || ""}
                handleChange={(e) => handleInput(e, { ...email })}
              />
              <Input
                type="text"
                name="email.emailSubject"
                labelText="Subject:"
                value={emailSubject || ""}
                handleChange={(e) => handleInput(e, { ...email })}
              />
              {/* Special Options for Invoices */}
              {type.includes("Invoice") && (
                <div>
                  <Input
                    type="text"
                    name="email.paymentLink"
                    labelText="Payment Link:"
                    value={paymentLink || ""}
                    handleChange={(e) => handlePaymentLink(e)}
                  />
                </div>
              )}
              {/* Special Options for Receipts */}
              {type.includes("Receipt") && (
                <div className="checkbox flex-parent">
                  <label className="form-label whole">Attachments:</label>
                  <div className="one-third">
                    <Checkbox
                      type="checkbox"
                      name="email.acraliteWarranty"
                      labelText="AcraLite Warranty"
                      value={"on"}
                      checked={acraliteWarranty}
                      handleChange={(e) => handleCheck(e, { ...email })}
                    />
                  </div>
                  <div className="one-third">
                    <Checkbox
                      type="checkbox"
                      name="email.veluxWarranty"
                      labelText="Velux Warranty"
                      value={"on"}
                      checked={veluxWarranty}
                      handleChange={(e) => handleCheck(e, { ...email })}
                    />
                  </div>
                  <div className="one-third">
                    <Checkbox
                      type="checkbox"
                      name="email.freeliteWarranty"
                      labelText="No Leak Warranty"
                      value={"on"}
                      checked={freeliteWarranty}
                      handleChange={(e) => handleCheck(e, { ...email })}
                    />
                  </div>
                </div>
              )}
              <TextArea
                name="email.emailBody"
                labelText="Body:"
                value={emailBody}
                handleChange={(e) => handleInput(e, { ...email })}
              />
            </div>
          )}
      </div>
      {/* calendar appt text */}
      <div className="whole">
        {sendEvent &&
          status?.toString() === "1" && ( // only display if create appt box is checked
            <div>
              <Input
                type="text"
                name="calendar.eventTitle"
                labelText="Calendar Event Title:"
                value={eventTitle || ""}
                handleChange={(e) => handleInput(e, { ...calendar })}
              />
              <Input
                type="text"
                name="calendar.eventAttendees"
                labelText="Attendees:"
                value={eventAttendees || ""}
                handleChange={(e) => handleInput(e, { ...calendar })}
              />
              <Input
                type="text"
                name="calendar.eventDescription"
                labelText="Event Description:"
                value={eventDescription || ""}
                handleChange={(e) => handleInput(e, { ...calendar })}
              />
            </div>
          )}
      </div>
      {/* Submit/ Clear Buttons */}
      <div className="flex-parent whole btn-container">
        <div className="one-half">
          <button
            name="complete"
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ? "please wait..." : "Complete"}
          </button>
        </div>
        <div className="one-half">
          <button
            name="cancel"
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default EmailReportForm;
