import { Routes, Route, BrowserRouter } from "react-router-dom";
import {
  Error,
  Login,
  ProtectedRouteUser,
  ProtectedRouteManager,
  ProtectedRouteAdmin,
  Reports,
  Warranty,
} from "./pages"; // Register,
import {
  AddJob,
  AllJobs,
  Admin,
  Appointments,
  Dashboard,
  JobSummary,
  SharedLayout,
  Payments,
  WorkSpecs,
  UserSettings,
} from "./pages/workspace/index.js";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Authenticated Users Only */}
        <Route
          path="/"
          element={
            <ProtectedRouteUser>
              <SharedLayout />
            </ProtectedRouteUser>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="all-jobs" element={<AllJobs />} />
          <Route path="add-job" element={<AddJob />} />
          <Route path="job-summary" element={<JobSummary />} />
          <Route path="work-specs" element={<WorkSpecs />} />
          <Route path="user-settings" element={<UserSettings />} />
          {/* Managers Only */}
          <Route
            path="appointments"
            element={
              <ProtectedRouteManager>
                <Appointments />
              </ProtectedRouteManager>
            }
          />
          <Route
            path="payments"
            element={
              <ProtectedRouteManager>
                <Payments />
              </ProtectedRouteManager>
            }
          />
          {/* Admins Only */}
          <Route
            path="admin"
            element={
              <ProtectedRouteAdmin>
                <Admin />
              </ProtectedRouteAdmin>
            }
          />
        </Route>
        {/* Reports PDF Route */}
        <Route
          path="reports/:jobId"
          element={
            <ProtectedRouteUser>
              <Reports />
            </ProtectedRouteUser>
          }
        />
        {/* Warranty PDF Route */}
        <Route
          path="warranty/:jobId"
          element={
            <ProtectedRouteUser>
              <Warranty />
            </ProtectedRouteUser>
          }
        />
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
