import { Input } from "../workspace";
import { useAppContext } from "../../context/appContext";

function Address() {
  const {
    handleChange,
    job,
    address,
    showAlert,
    displayAlert,
    isLoading,
    saveRecord,
  } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!address.id) {
      displayAlert("address is missing id");
      return;
    }
    saveRecord(`/address/update/${address.id}`, address, "Address");
  };

  const handleInput = (e, baseObject) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "address.zip") {
      value = Number(value);
    }
    handleChange({ baseObject, name, value });
  };
  return (
    <div className="group one-third">
      <form onSubmit={handleSubmit} className="form">
        <h3>Address</h3>
        <div className="form-center">
          <div className="whole">
            <Input
              type="text"
              name="address.street_address"
              labelText="Street"
              value={address.street_address || ""}
              handleChange={(e) => handleInput(e, { ...address })}
            />
          </div>
          <div className="whole">
            <Input
              type="text"
              name="address.city"
              labelText="City"
              value={address.city || ""}
              handleChange={(e) => handleInput(e, { ...address })}
            />
          </div>
          <div className="one-half">
            <Input
              type="text"
              name="address.zip"
              labelText="Zip Code"
              value={address.zip || ""}
              handleChange={(e) => handleInput(e, { ...address })}
            />
          </div>
          <div className="one-half">
            <Input
              type="text"
              name="address.state"
              labelText="State"
              value={address.state || ""}
              handleChange={(e) => handleInput(e, { ...address })}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
export default Address;
