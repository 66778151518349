import { useState, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import { FileModal } from "../../components/JobSummary";
import convert from "heic-convert/browser";
import { iterateName } from "../../utils/fileNames";

function FileFolder() {
  const { job, files, user, isLoading } = useAppContext();

  const [showFileModal, setShowFileModal] = useState(false);
  const newFile = useRef(null);
  const [fileMetadata, setFileMetadata] = useState(null);
  const [newName, setNewName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const maxUserRolesId = 3; // service rep

  const openFolder = (e) => {
    e.preventDefault();

    // check for development/production environment
    let basePath =
      "https://freelite-my.sharepoint.com/personal/skylights_freelite_onmicrosoft_com/Documents/";
    if (process.env.NODE_ENV === "development") {
      console.log("Development mode!");
      basePath += "IT/JMS3/";
    } else {
      basePath += "FreeliteJMS3/";
    }

    // check for sandbox mode
    let jobPath = job.folder_path;
    console.log("userMode:", user.user_mode);
    if (user.user_mode) {
      jobPath = "sandbox/" + jobPath;
    }
    const url = basePath + jobPath;
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const handleFileInput = async (e) => {
    console.log("file to upload:", e.target.files[0]);
    const file = e.target.files[0];
    newFile.current = file; // used to store the actual file object
    const reader = new FileReader();
    if (file) {
      // Load preview image of photo
      if (file.name.includes(".heic")) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }
      reader.onloadend = async () => {
        let convertedBuffer = reader.result;
        console.log("convertedBuffer:", convertedBuffer);
        if (file.name.includes(".heic")) {
          const buffer = new Uint8Array(convertedBuffer);
          convertedBuffer = await convert({
            buffer: buffer, // the HEIC file buffer
            format: "JPEG", // output format
            quality: 0.9, // the jpeg compression quality, between 0 and 1
          });
          // Convert the ArrayBuffer to a data URL string for preview
          const blob = new Blob([convertedBuffer], { type: "image/jpeg" });
          convertedBuffer = URL.createObjectURL(blob);
        }
        setImagePreview(convertedBuffer);
      };

      // Determine and set new file name
      const baseFileName = `${job.job_number} Attachment`;
      const fileName = iterateName(baseFileName, files);
      setNewName(fileName);
      // Add Dimensions
      setFileMetadata(file);
      // Show upload form
      setShowFileModal(true);
    }
  };

  return (
    <div className="group one-third">
      <form className="form">
        <h3>File Folder</h3>
        {files.length > 0 ? (
          <div className="form-center flex-parent">
            <div className="subform">
              <div className="files">
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: 190 }}>File Name</th>
                      <th style={{ width: 50 }}>Size</th>
                    </tr>
                    {files?.map((file, index) => {
                      const { id, name, webUrl, size } = file;
                      return (
                        <tr
                          key={id || 0}
                          className=""
                          onClick={(e) => console.log("open that file...")}
                        >
                          <td>
                            <a
                              href={webUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {name}
                            </a>
                          </td>
                          <td>
                            {/* divide size into kilobytes then into megabytes */}
                            {(size / 1024 / 1000).toFixed(2)} MB
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {user.user_roles_id <= maxUserRolesId && (
              <>
                <div className="one-half form-center">
                  <label className="file-upload btn-block">
                    <span className="btn label-button">
                      {isLoading ? "please wait..." : "Upload File"}
                    </span>
                    <input
                      type="file"
                      name="file"
                      className="btn btn-block"
                      onChange={handleFileInput}
                    />
                  </label>
                </div>
                <div className="one-half">
                  <button
                    className="btn btn-block"
                    disabled={isLoading}
                    onClick={(e) => openFolder(e)}
                  >
                    {isLoading ? "please wait..." : "View Folder"}
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          "No file folder found for this job."
        )}
      </form>
      {/* ----- Add Photo Modal ------ */}
      <div
        className={
          showFileModal ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <FileModal
            newFile={newFile.current}
            fileMetadata={fileMetadata}
            setFileMetadata={setFileMetadata}
            newName={newName}
            setNewName={setNewName}
            imagePreview={imagePreview}
            setImagePreview={setImagePreview}
            setShowFileModal={setShowFileModal}
          />
        </div>
      </div>
    </div>
  );
}
export default FileFolder;
