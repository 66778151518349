import { Input, Select, Checkbox } from "../workspace";
import { useAppContext } from "../../context/appContext";

function NewCustomer({ toggleModal }) {
  const {
    handleChange,
    handleChanges,
    newJob,
    newCustomer,
    displayAlert,
    isLoading,
    lists,
    saveRecord,
    createRecord,
    authFetch,
    clearChanges,
    clearArrayChanges,
    getCustomerMatchInfo,
    getJobLists,
  } = useAppContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for duplicate customer name
    const duplicateName = lists.customerNames?.filter(
      (cust) => cust.text === newCustomer.customer_name
    );
    if (duplicateName.length > 0) {
      displayAlert("A customer with that name already exists.");
      return;
    }
    // Check for empty values
    if (!newCustomer.customer_name) {
      displayAlert("Please add a customer name.");
      return;
    }
    if (newCustomer.lead_id === "default") {
      displayAlert("Please select a lead source.");
      return;
    }
    if (!newCustomer.id) {
      // Make changes for new customer
      const record = await createRecord(
        "/customer/create",
        newCustomer,
        "Customer"
      );
      console.log("record:", record);
      handleChange({
        baseObject: { ...newCustomer },
        name: "newCustomer.id",
        value: record.id,
      });
      handleChange({
        baseObject: { ...newJob },
        name: "newJob.customer_id",
        value: record.id,
      });
    } else {
      // Or update existing customer info
      saveRecord(`/customer/update/${newCustomer.id}`, newCustomer, "Customer");
    }
    getJobLists();
  };

  const handleInput = (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
  };
  const handleCustomerChange = async (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
    const match = lists?.customerNames?.find((customer) => {
      return customer.text === value;
    });
    if (match) {
      // Get corresponding customer, contacts, and address matches
      await getCustomerMatchInfo(match.key);
      // check for matching addresses and list any as an option
      toggleModal();
    }
  };
  const handleCheckBox = (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.checked; // grab checked instead of value
    handleChange({ baseObject, name, value });
  };

  const filterResults = () => {
    const customerName = newCustomer.customer_name || "";
    if (!lists.customerNames) {
      return [];
    }
    let filtered = lists.customerNames;
    // filter results
    filtered = filtered?.filter((listItem) => {
      const { text } = listItem;
      // build lowercase variables for an easy case agnostic search
      const procFilter = customerName.toLowerCase();
      const procText = text.toLowerCase();
      // check the search input text for matches
      let match = false;
      if (procText.includes(procFilter)) {
        match = true;
      }
      return match;
    });
    return filtered;
  };

  const clearCustomer = (e) => {
    e.preventDefault();
    // clear customer
    let baseName = "newCustomer";
    let defaultState = {
      customer_name: "",
      id: "",
      lead_id: "default",
      roofer_discount: false,
    };
    clearChanges({ baseName, defaultState });
    // clear contact info from summary and pick list
    baseName = "newContacts";
    defaultState = [];
    clearArrayChanges({ baseName, defaultState });
    //  clear address info
    baseName = "newAddress";
    defaultState = { state: "TX" };
    clearChanges({ baseName, defaultState });
    // reset ids in core job data
    let newValues = { default_contact: null, address_id: null };
    handleChanges({ baseObject: newJob, baseName: "newJob", newValues });
  };

  return (
    <div name="newCustomer" className="group one-third">
      <form onSubmit={handleSubmit} className="form">
        <h3>Customer</h3>
        <div className="form-center sub-form">
          <div className="whole">
            <Input
              type="text"
              name="newCustomer.customer_name"
              labelText="Customer Name"
              value={newCustomer.customer_name || ""}
              dataList={{
                id: "customer-names",
                options: filterResults(),
              }}
              handleChange={(e) => handleCustomerChange(e, { ...newCustomer })}
            />
          </div>
          <div className="whole">
            <Select
              options={lists && lists.listLeadSources}
              name="newCustomer.lead_id"
              labelText="Lead Source"
              value={newCustomer.lead_id}
              handleChange={(e) => handleInput(e, { ...newCustomer })}
            />
          </div>
          <div className="whole checkbox">
            <Checkbox
              type="checkbox"
              name="newCustomer.roofer_discount"
              labelText="Roofer Discount"
              checked={newCustomer.roofer_discount}
              handleChange={(e) => handleCheckBox(e, { ...newCustomer })}
            />
          </div>
          <div className="whole">
            <button
              name="clearCustomer"
              className="btn btn-block btn-danger form-row"
              type="button"
              disabled={isLoading}
              onClick={clearCustomer}
            >
              {isLoading ? "please wait..." : "Clear Customer"}
            </button>
          </div>
          {!newCustomer.id && (
            <button
              name="addCustomer"
              className="btn btn-block"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "please wait..." : "Add"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
export default NewCustomer;
