import React, { useState, useEffect } from "react";
import { Logo, Alert } from "../components";
import Wrapper from "../assets/wrappers/LoginPage";
import { useAppContext } from "../context/appContext";
import { useNavigate } from "react-router-dom";
import { Input, Checkbox } from "../components/workspace";

const initialState = {
  name: "",
  password: "",
  email: "",
};

function Login() {
  const [values, setValues] = useState(initialState);
  const [showPass, setShowPass] = useState(false);

  const { user, isLoading, showAlert, displayAlert, loginUser, version } =
    useAppContext();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, password } = values;
    if (!name || !password) {
      displayAlert("Please fill in name and password.");
      return;
    }
    const currentUser = { name, password };

    // console.log("currentUser:", currentUser);
    loginUser(currentUser);

    // setTimeout(() => {
    //   console.log("about to navigate...");
    //   navigate("../all-jobs");
    // }, 1200);
  };
  // logins in a user if a valid user is identified
  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("../all-jobs");
      }, 1200);
    }
  }, [user, navigate]);

  return (
    <Wrapper className="full-page login">
      <form className="form" onSubmit={handleSubmit}>
        <Logo />
        {showAlert && <Alert />}
        {/* name input */}
        <Input
          type="text"
          name="name"
          value={values.name}
          handleChange={handleChange}
          labelText="name"
        />
        {/* password input */}
        <Input
          type={showPass ? "text" : "password"}
          name="password"
          value={values.password}
          handleChange={handleChange}
          labelText="password"
        />
        {/* display password control */}
        <div className="checkbox show-pass">
          <Checkbox
            type="checkbox"
            name="showPass"
            labelText="show password"
            checked={showPass || false}
            handleChange={() => setShowPass(!showPass)}
          />
        </div>
        <button type="submit" className="btn btn-block" disabled={isLoading}>
          Submit
        </button>
        <div className="program">{`FreeLite JMS 3 v. ${version}`}</div>
      </form>
    </Wrapper>
  );
}

export default Login;
