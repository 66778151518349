import { useState } from "react";
import { FaWarehouse, FaMapMarkedAlt } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useAppContext } from "../../context/appContext";
import {
  APIProvider,
  Map,
  Marker,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";

function JobMap({ chosenAppt, setChosenAppt, MapLink }) {
  const { calendarAppts } = useAppContext();

  const [resetCamera, setResetCamera] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "500px",
  };
  const center = { lat: 32.89, lng: -96.87 };
  const office = { lat: 32.9126102, lng: -96.6278813 };

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const handleClose = () => {
    console.log("Closing appointment window...");
    console.log("Old chosenAppt:", chosenAppt);
    setChosenAppt(null);
    setResetCamera(false);
  };

  const handleSelect = (appt) => {
    console.log("Direct map select...");
    console.log("appt?.gps:", appt?.gps);
    setChosenAppt(appt);
    setResetCamera(false);
  };

  const handleOfficeSelect = () => {
    console.log("Office icon select...");
    setChosenAppt(null);
    setResetCamera(true);
  };

  const freeCamera = () => {
    setResetCamera(false);
  };

  return (
    <div className="group one-half">
      <form className="form">
        <div className="form-center">
          <APIProvider apiKey={apiKey}>
            <Map
              mapId={"map1"}
              style={containerStyle}
              defaultCenter={center}
              defaultZoom={10}
              center={resetCamera ? center : chosenAppt?.gps}
              zoom={resetCamera && 10}
              onClick={handleClose}
              onDrag={handleClose}
              onZoomChanged={freeCamera}
              onMouseover={freeCamera}
            >
              {/* Office Location */}
              <AdvancedMarker
                position={office}
                title={`FreeLite Office`}
                onClick={handleOfficeSelect}
              >
                <IconContext.Provider value={{ className: "map-icon" }}>
                  <div>
                    <FaWarehouse />
                  </div>
                </IconContext.Provider>
              </AdvancedMarker>
              {/* Job Location Markers */}
              {calendarAppts.map(
                (appt, index) =>
                  appt?.gps && (
                    <div key={index}>
                      <Marker
                        key={index}
                        position={appt.gps}
                        title={appt.title}
                        icon={{
                          url: `/map-icons/number_${appt.listNumber}.png`,
                        }}
                        onClick={() => handleSelect(appt)}
                      />
                    </div>
                  )
              )}
              {/* Info Window */}
              {chosenAppt?.gps?.lat && (
                <InfoWindow
                  position={{
                    lat: chosenAppt?.gps?.lat,
                    lng: chosenAppt?.gps?.lng,
                  }}
                  onCloseClick={handleClose}
                  headerContent={
                    <div className="map-modal-title">
                      {chosenAppt.listNumber}
                    </div>
                  }
                >
                  <div className="map-modal">
                    <h5>{chosenAppt?.title}</h5>
                    <p>
                      {chosenAppt?.resource}
                      <MapLink address={chosenAppt?.resource} />
                    </p>
                    {/* <p>{chosenAppt?.desc}</p> */}
                  </div>
                </InfoWindow>
              )}
            </Map>
          </APIProvider>
        </div>
      </form>
    </div>
  );
}
export default JobMap;

function holding(
  apiKey,
  calendarAppts,
  chosenAppt,
  containerStyle,
  center,
  handleClose,
  office,
  handleSelect
) {
  <APIProvider apiKey={apiKey}>
    <Map
      style={containerStyle}
      defaultCenter={center}
      defaultZoom={10}
      onClick={handleClose}
    >
      {/* Office Location */}
      <Marker position={office} title={`FreeLite Office`} />
      {/* Job Location Markers */}
      {calendarAppts.map(
        (appt, index) =>
          appt?.gps && (
            <div key={index}>
              <Marker
                key={index}
                position={appt.gps}
                title={appt.title}
                icon={{
                  url: `/map-icons/number_${appt.listNumber}.png`,
                }}
                onClick={() => handleSelect(appt)}
              />
            </div>
          )
      )}
      {/* Info Window */}
      {/* {chosenAppt?.title === "Skylight Service Work S24-10-015" && ( */}
      {chosenAppt?.gps?.lat && (
        <InfoWindow
          position={{
            lat: chosenAppt?.gps?.lat + 0.03,
            lng: chosenAppt?.gps?.lng,
          }}
          style={{ color: "#0f9d58" }}
          onCloseClick={handleClose}
        >
          <div className="map-modal">
            <h5>{chosenAppt?.title}</h5>
            <p>
              {chosenAppt?.resource}

              <a
                href={`https://www.google.com/maps/place/${chosenAppt?.resource}/`}
                target="_blank"
                rel="noreferrer"
              >
                <FaMapMarkedAlt />
              </a>
            </p>
            {/* <p>{chosenAppt?.desc}</p> */}
          </div>
        </InfoWindow>
      )}
    </Map>
  </APIProvider>;
}
