import styled from "styled-components";

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  .nav-center {
    display: flex;
    width: 96vw;
    align-items: center;
    justify-content: space-between;
  }
  .select-job {
    width: 50%;
    min-width: 240px;
    margin: 0 1.25rem;
  }

  .toggle-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1.75rem;
    color: var(--primary-500);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  background: var(--white);
  .nav-center .btn-container {
    position: relative;
    align-self: center;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    position: relative;
    box-shadow: var(--shadow-2);
  }

  @media (min-width: 992px) {
    .nav-center {
      width: 96%;
    }
    .logo {
      /* display: none; */
    }
    .logo-text {
      display: block;
    }
    .user-name {
      display: block;
    }
  }
`;
export default Wrapper;
