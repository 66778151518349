import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { TextArea, Select } from "../../components/workspace";
import {
  NewAddress,
  NewCustomer,
  NewContacts,
  CustomerMatchModal,
} from "../../components/AddJob";
import { useNavigate } from "react-router-dom";

function AddJob() {
  const {
    handleChange,
    newJob,
    lists,
    displayAlert,
    isLoading,
    createJob,
    getCurrentJobNumber,
    newAddress,
    newCustomer,
    newContacts,
  } = useAppContext();
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowForm(!showForm);
  };
  const createNewJob = async (e) => {
    e.preventDefault();

    if (!newJob.job_type_id || !newJob.default_contact) {
      displayAlert("Please select a Job Type and Current Contact");
      return;
    }
    newJob.job_status = "2"; // Open estimate by default
    // ------ Build Job Number ------
    const date = new Date();
    newJob.first_contact_date = date.toJSON();
    let year = date.toLocaleDateString("en-US", { year: "2-digit" });
    let month = date.toLocaleDateString("en-US", { month: "2-digit" });
    // fetch the most recent job number for the month (if any)
    const job_number = await getCurrentJobNumber(
      `utilities/job-number/${year}-${month}`
    );
    // set pricesheet id to most recent version
    newJob.price_sheets_id = lists.listPriceSheets[0].key;
    // get the last digits of a match and pad it to 3 digits
    let jobSpot = 0;
    if (job_number) {
      // console.log("last job_number:", job_number);
      jobSpot = parseInt(job_number.slice(-3));
    }
    jobSpot++;
    let endNumber = jobSpot.toString().padStart(3, 0);
    // Add letter prefix and interpolate full job number
    let jobLetter = "S";
    if (newJob.job_type_id === "3") {
      jobLetter = "I";
    } else if (newJob.job_type_id === "4") {
      jobLetter = "C";
    }
    newJob.job_number = `${jobLetter}${year}-${month}-${endNumber}`;
    // ------ Build Folder Path ------
    year = date.toLocaleDateString("en-US", { year: "numeric" });
    month = date.toLocaleDateString("en-US", { month: "long" });
    newJob.folder_path = `${year}/${month}/${newJob.job_number}`;
    // create the core job record in the db
    const createdJob = await createJob();
    // forward user to Appointments page
    if (createdJob) navigate("../appointments");
  };
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const baseObject = { ...newJob };
    handleChange({ baseObject, name, value });
  };

  return (
    <div className="page">
      {/* ----- Customer ------ */}
      <NewCustomer toggleModal={toggleModal} />
      {/* ----- Contacts ------ */}
      <NewContacts />
      {/* ----- Address ------ */}
      <NewAddress />
      {/* ----- Job Info ------ */}
      <div name="jobInfo" className="group two-thirds">
        {newCustomer.id && newAddress.id ? (
          <>
            <form onSubmit={createNewJob} className="form">
              <h3>New Job</h3>
              <div className="form-center">
                <div className="one-half">
                  <Select
                    name="newJob.job_type_id"
                    labelText="Job Type"
                    value={newJob.job_type_id}
                    handleChange={(e) => handleInput(e)}
                    options={lists && lists.listJobTypes}
                  />
                </div>
                <div className="one-half">
                  <Select
                    name="newJob.default_contact"
                    labelText="Current Contact"
                    value={newJob.default_contact}
                    handleChange={(e) => handleInput(e)}
                    // options={lists && lists.listNewContacts}
                    options={
                      newContacts &&
                      newContacts
                        .filter((cnt) => cnt.is_active)
                        .filter((cnt) => cnt.id)
                        .map((cnt) => {
                          return { key: cnt.id, text: cnt.contact_name };
                        })
                    }
                  />
                </div>
                <div className="whole">
                  <TextArea
                    name="newJob.office_job_notes"
                    labelText="Job Notes"
                    value={newJob.office_job_notes}
                    handleChange={(e) => handleInput(e)}
                  />
                </div>
                <button
                  name="addJob"
                  className="btn btn-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "Add Job"}
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <h3>Please save a customer and address to continue...</h3>
          </>
        )}
      </div>
      {/* ----- Address Selection Modal ------ */}
      <div
        className={showForm ? "show-modal modal-container" : "modal-container"}
      >
        <div className="group">
          <CustomerMatchModal toggleModal={toggleModal} />
        </div>
      </div>
    </div>
  );
}
export default AddJob;
