import { useState, useEffect } from "react";
import { useAppContext } from "../../context/appContext";
import { Select, Display, Display1 } from "../workspace";
import { FaTimes } from "react-icons/fa";

function CustomerMatchModal({ toggleModal }) {
  const {
    isLoading,
    newJob,
    customerMatchInfo,
    handleChange,
    getRecord,
    getArrayRecord,
  } = useAppContext();
  const { matchingCustomer, matchingContacts, matchingAddresses } =
    customerMatchInfo;

  const contactsString = matchingContacts?.reduce((str, cnt) => {
    if (cnt.is_active)
      return (
        str +
        `${cnt.contact_name} - ${cnt.email || "no email"} - ${
          cnt.phone || "no phone"
        }` +
        "\n"
      );
  }, "");

  const [chosenAddress, setChosenAddress] = useState();

  const handleInput = (e) => {
    const value = e.target.value;
    setChosenAddress(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Update selected customer
    const custId = matchingCustomer.id;
    handleChange({
      baseObject: { ...newJob },
      name: "newJob.customer_id",
      value: custId,
    });
    // Load matching customer
    getRecord(`/customer/${custId}`, "newCustomer", "customer");
    // Load corresponding contacts
    getArrayRecord(`/contact/${custId}`, "newContacts", "contact");
    // Update address if one is chosen
    console.log("chosenAddress:", chosenAddress);
    if (chosenAddress > 0) {
      // update job with new address id
      handleChange({
        baseObject: { ...newJob },
        name: "newJob.address_id",
        value: chosenAddress,
      });
      // load chosen address
      getRecord(`/address/${chosenAddress}`, "newAddress", "address");
    }
    // close form
    toggleModal();
  };
  return (
    <form className="form email-report-form">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={toggleModal}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <div className="">
          <h3>Select previous customer?</h3>
        </div>
      </div>
      {/* Customer */}
      <Display
        name="customer_name"
        label="Customer"
        value={matchingCustomer?.customer_name}
      />
      {/* Contacts */}
      <Display1
        name="Contacts"
        label="Acive Contacts:"
        value={contactsString || ""}
        handleChange={() => {}}
      />
      <div className="whole form-row display">
        <Select
          name="chosenAddress"
          labelText="Matching Addresses:"
          value={chosenAddress}
          handleChange={(e) => handleInput(e)}
          options={matchingAddresses}
        />
      </div>

      {/* Submit/ Clear Buttons */}
      <div className="btn-container flex-parent whole bottom-buttons">
        <div className="one-half">
          <button
            name="selectCustomer"
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ? "please wait..." : "Select Customer"}
          </button>
        </div>
        <div className="one-half">
          <button
            name="cancelAddress"
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={toggleModal}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default CustomerMatchModal;
