import { Alert } from "../";
import { useAppContext } from "../../context/appContext";
import { Select } from "../workspace";

const AdminCustomSettings = () => {
  const { isLoading, job, showAlert, lists, handleChange, saveRecord } =
    useAppContext();

  const handleInput = (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    saveRecord(`/core-job/update/${job.id}`, job, "Core Job Info");
    console.log("update records");
  };
  return (
    <div className="group whole">
      <form onSubmit={handleSubmit} className="form">
        <h3>Custom Settings</h3>
        {showAlert && <Alert />}
        <div className="form-center">
          <div className="whole">
            <Select
              name="job.job_type_id"
              labelText="Change Job Type"
              value={job.job_type_id}
              handleChange={(e) => handleInput(e, { ...job })}
              options={lists && lists.listJobTypes}
            />
          </div>
          <div className="whole">
            <Select
              name="job.price_sheets_id"
              labelText="Change Price Sheet"
              value={job.price_sheets_id}
              handleChange={(e) => handleInput(e, { ...job })}
              options={lists && lists.listPriceSheets}
            />
          </div>

          <button className="btn btn-block" type="submit" disabled={isLoading}>
            {isLoading ? "please wait..." : "Save Changes"}
          </button>
        </div>
      </form>
      {/* <div className="whole">
        <h3>Recent Changes</h3>
        <div className="scroll">
          <table>
            <tbody>
              <tr>
                <th>&nbsp;Date</th>
                <th>&nbsp;Time</th>
                <th>&nbsp;Page</th>
                <th>&nbsp;Items</th>
                <th>&nbsp;Employee</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
};
export default AdminCustomSettings;
