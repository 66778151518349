import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { useNavigate } from "react-router-dom";
import { Input, Select } from "../../components/workspace";

function AllJobs() {
  const { lists, loadMainRecords } = useAppContext();

  const [selectedRow, setSelectedRow] = useState(null);
  // const [filteredSearch, setFilteredSearch] = useState(lists.allJobs ?? []);
  const [filters, setFilters] = useState({
    search: "",
    jobType: "default",
    jobStatus: "default",
  });
  const navigate = useNavigate();
  const resetFilters = () => {
    setFilters({
      search: "",
      jobType: "default",
      jobStatus: "default",
    });
  };
  const filterResults = (newFilters) => {
    if (!lists.allJobs) {
      return;
    }
    let filtered = lists.allJobs;
    // filter results
    filtered = filtered?.filter((jobObject) => {
      const {
        customer_name,
        street_address,
        office_job_notes,
        job_status_id,
        job_type_id,
        job_number,
        phones,
        emails,
      } = jobObject;
      // build lowercase variables for an easy case agnostic search
      const procFilter = newFilters.search?.toLowerCase();
      const nameSearch = customer_name?.toLowerCase();
      const addressSearch = street_address?.toLowerCase();
      const notesSearch = office_job_notes?.toLowerCase();
      const jobNumberSearch = job_number?.toLowerCase();
      let match = false;
      // check the search input text for matches
      if (
        nameSearch?.includes(procFilter) ||
        addressSearch?.includes(procFilter) ||
        notesSearch?.includes(procFilter) ||
        jobNumberSearch?.includes(procFilter)
      ) {
        match = true;
      }
      // check arrays of contact info for matches
      if (phones?.filter((phone) => phone?.includes(procFilter)).length > 0) {
        // console.log("phones:", phones);
        match = true; // if any matches found then set to true
      }
      if (emails?.filter((email) => email?.includes(procFilter)).length > 0) {
        match = true; // if any matches found then set to true
      }
      // check for job status matches
      if (
        newFilters.jobStatus !== job_status_id && // if status fails to match
        newFilters.jobStatus !== "default" // when the filter is set
      ) {
        return false; // return the listed job as not matching
      }
      // check for job type matches
      if (
        newFilters.jobType !== job_type_id && // if type fails to match
        newFilters.jobType !== "default" // when the filter is set
      ) {
        return false; // return the listed job as not matching
      }

      return match;
    });
    return filtered;
  };
  const handleInput = (e, number) => {
    const name = e.target.name;
    const value = number ? Number(e.target.value) : e.target.value;
    setFilters({
      ...filters,
      [name]: value,
    });
    filterResults({
      ...filters,
      [name]: value,
    });
  };
  const selectRow = async (e, jobId) => {
    if (e.target.parentNode.className === "selected") {
      loadMainRecords(jobId);
      navigate("../job-summary");
    }
    setSelectedRow(jobId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="page">
      {/* ----- Search Header ------ */}
      <div className="group whole">
        <form className="form" onSubmit={handleSubmit}>
          <h3>All Jobs</h3>
          <div className="form-center">
            {/* Search bar and clear filters button */}
            <div className="one-half flex-parent">
              <button className="btn red" type="button" onClick={resetFilters}>
                Clear Filters
              </button>
              <div className="one-half">
                <Input
                  type="text"
                  name="search"
                  value={filters.search}
                  handleChange={(e) => handleInput(e)}
                />
              </div>
            </div>
            {/* Job Status and Job Type filters */}
            <div className="one-half mobile-no-show flex-parent">
              <div className="one-half">
                <Select
                  name="jobStatus"
                  labelText="Job Status"
                  value={filters.jobStatus}
                  handleChange={(e) => handleInput(e, true)}
                  options={lists && lists.listJobStatus}
                />
              </div>
              <div className="one-half">
                <Select
                  name="jobType"
                  labelText="Job Type"
                  value={filters.jobType}
                  handleChange={(e) => handleInput(e, true)}
                  options={lists && lists.listJobTypes}
                />
              </div>
            </div>
            <div className="whole">
              <table>
                <thead>
                  <tr>
                    <th>Job #</th>
                    <th style={{ width: 190 }}>Customer Name</th>
                    <th style={{ width: 124 }}>Job Status</th>
                    <th style={{ width: 180 }}>Address</th>
                    <th className="mobile-no-show">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {filterResults(filters)?.map((listedJob, index) => {
                    return index < 40 ? (
                      <tr
                        key={listedJob.id}
                        className={
                          selectedRow === listedJob.id ? "selected" : ""
                        }
                        onClick={(e) => selectRow(e, listedJob.id)}
                      >
                        <td>{listedJob.job_number}</td>
                        <td>{listedJob.customer_name}</td>
                        <td>
                          {listedJob.job_status_id &&
                            lists.listJobStatus?.find((status) => {
                              return status.id === listedJob.job_status_id;
                            }).text}
                        </td>
                        <td>{listedJob.street_address}</td>
                        <td className="mobile-no-show">
                          {listedJob.office_job_notes}
                        </td>
                      </tr>
                    ) : (
                      ""
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default AllJobs;
