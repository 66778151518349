import styled from "styled-components";

const Wrapper = styled.section`
  html {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .dashboard {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .dashboard-page {
    width: 98vw;
    margin: 0 auto;
    padding: 0.5rem 0;
  }
  .main-window {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .wrapper > * {
    flex: 1 100%;
  }
  .flex-parent {
    display: flex;
    flex-flow: row wrap;
  }
  .green {
    background-color: var(--green-dark);
  }
  .green:hover {
    background-color: var(--green-light);
  }
  .red {
    background-color: var(--red-dark);
  }
  .red:hover {
    background-color: var(--red-light);
  }
  .page {
    display: flex;
    flex-flow: row wrap;
  }
  .group {
    border-radius: var(--borderRadius);
    background: var(--white);
    padding: .5rem 1.25rem 1.5rem;
    box-shadow: var(--shadow-2);
    margin: 0.5rem 0.65rem;
  }
  .whole {
    flex: 12 1 98%;
  }
  .one-half {
    flex: 6 6 44%;
    min-width: 240px;
  }
  .flex-parent .one-half {
    min-width: 102px;
  }
  .one-third {
    flex: 4 4 28%;
    min-width: 120px;
  }
  .flex-parent .one-third {
    min-width: 70px;
  }
  .two-thirds {
    flex: 8 8 57.5%;
    /* min-width: 64%; */
  }
  .one-fourth {
    flex: 3 3 20%;
    min-width: 90px;
  }
  .three-fourths {
    flex: 9 9 70%;
    /* min-width: 70%; */
  }
  h1,
  h2 {
    text-align: center;
    margin: 24px auto 12px;
  }
  h3 {
    margin: 0 0 3px;
  }
  .sandbox {
    background: var(--yellow);
    border: 2px solid var(--red-light);
  }

  /* ------- Forms ------- */

  .group.one-third {
    min-width: 293px;
  }
  .form {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
    width: 100%;
  }
  .form-row {
    margin-bottom: 1rem;
  }
  .input-control {
    height: 71px;
  }
  .form-center {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
  }
  .form-center button {
    align-self: center;
    margin-top: 1rem;
  }
  .form-center .flex-parent {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-self: flex-end;
    margin-top: 0.5rem;
  }
  .clear-btn {
    background: var(--grey-500);
  }
  .clear-btn:hover {
    background: var(--black);
  }
  .contacts .checkbox .form-row {
    height: 36.8px;
  }
  .checkbox {
    .form-row {
      display: flex;
      height: 71px;
      align-items: center;
      width: 100%;
    }
    input {
      height: 16px;
      max-width: 40px;
      margin-bottom: 0.7rem;
    }
    label {
      display: inline;
    }
    .text-box {
      height: 35px;
      max-width: 7rem;
      margin-right: 1rem;
    }
    .text-box .wide {
      width: 100%;
    }
  }
  .outline-box {
    padding: 1rem;
    border: 1px solid var(--grey-200);
    border-radius: var(--borderRadius);
  }
  .checked {
    padding: 1rem;
    border: 1px solid var(--primary-300);
    background-color: var(--primary-50);
  }
  .confirm-report {
    margin-bottom: 2rem;
  }
  .email-report-form .checkbox label {
    font-size: var(--text-medium);
  }
  .display {
    padding: 4px 0 0 4px;
    font-size: var(--text-large);
    margin: 10px 0;
    border-radius: var(--borderRadius);
    label {
      color: var(--grey-500); // --grey-500
    }
  }
  .base-info .display {
    margin: 0.25em 0;
  }
  .no-label {
    label {
      display: none;
    }
  }
  .close-btn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    background: transparent;
    border-color: transparent;
    font-size: 2rem;
    color: var(--red-dark);
    cursor: pointer;
  }
  .selected {
    background-color: var(--primary-700);
    box-shadow: var(--shadow-3);
  }
  .pick-list {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: var(--transition);
    overflow: scroll;
    .btn {
      background-color: var(--primary-300);
      /* color: var(--grey-800); */
    }
  }
  .show-pick-list {
    z-index: 91;
    opacity: 1;
  }
  .pick-btn .btn,
  .btn-block .btn {
    margin: 0 1px 1rem;
    height: 35px;
  }
  .section {
    margin: 36px 0 12px;
    break-inside: avoid;
  }
  .option {
    margin: 40px 0 10px;
  }
  .money .value {
    text-align: right;
    padding-right: 1rem;
    font-family: var(--mono-font);
  }
  .one-half .form-label {
    // min-width: 0;
  }

  /* ------- Sub-forms ------- */

  .subform {
    font-size: 10pt;
    line-height: 1.3rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: var(--primary-50);
    .form-row {
      margin-top: 0.1rem;
      margin-bottom: 0.2rem;
    }
    .sub-item {
      padding: 0.5rem;
    }
    .sub-item:nth-child(even) {
      background-color: var(--primary-25);
    }
    .form-input {
      height: 1.5rem;
    }
    .form-label {
      margin-bottom: 0.2rem;
    }
    .checkbox input {
      height: 0.8rem;
      margin: auto 0;
    }
    .flex-parent {
      column-gap: 0.5rem;
    }
    .btn {
      margin-top: 1rem;
      background-color: var(--primary-700);
    }
    .btn:hover {
      background: var(--primary-200);
      // color: var(--gray-50);
    }
    .red {
      background-color: var(--red-dark);
    }
    .red:hover {
      background-color: var(--red-light);
      // color: var(--gray-50);
    }
  }
  .subform:first-line {
    line-height: 0.3rem;
  }
  .sub-section {
    padding: 0 1rem 0 0;
  }
  .work-specs .subform {
    font-size: 11pt;
    .btn {
      margin: 0.75rem 0 0.5rem;
      height: 1.6rem;
    }
    .display {
      font-size: var(--text-extra-large)
      margin: 0.75rem 0.25rem;
      width: 100%;
    }
    .form-textarea {
      height: 12rem;
    }
    .details label {
      font-size: var(--text-small);
      line-height: 0.3rem;
      margin-bottom: 1.1rem;
    }
    .details .value {
      font-size: var(--text-smaller);
      line-height: 1.5rem;
    }
  }

  /* ------- PDF Reports ------- */

  .report {
    padding: 4rem 2rem;
    font-size: 1rem;
    color: var(--text-color);
    max-width:8.5in;
    margin:auto;
    background-color:white;
  }
  .report h1 {
    font-size: 2.0rem;
    margin-top: 3rem;
  }
  .report h2 {
    font-size: 1.7rem;
  }
  .report h3 {
    font-size: 1.3rem;;
  }
  .report label {
    // font-size: 0.8rem;
  }
  .report .display {
    font-size: var(--text-small);
  }
  .report .subform {
    margin: 12px 0;
    line-height: 1.5rem;
    background-color: var(--primary-50);
  }
  .report .form-textarea {
    height: 10rem;
  }
  .report .section {
    margin: 2.5rem 0 1rem;
  }
  .report .group {
    border-radius: 0;
    box-shadow: none;
    margin: auto;
  }
  .report .option {
    margin: 0 0 4rem;
  }
  .report .sub-item {
    break-inside: avoid;
  }
  .price-summary .display {
    font-size: 1.2rem;
  }
  .bold {
    font-weight: 700;
  }
  .footer {
    text-align: center;
  }
  .details .value {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .warranty {
    padding: 4rem 6rem;
    font-size: 1rem;
    color: var(--text-color);
    // background: var(--white);
  }
  .before-after-photos {
    display: flex;
    flex-flow: row wrap;
    width: 98%
    display:block;
    column-gap: .5em;
  }
  .four-by-three-image {
    display: flex;
    justify-content: center;
    margin-bottom: .25em
  }
  .three-by-four-image {
    margin-bottom: .25em
  }

  /* ------- Specific Controls ------- */

  .user-settings div {
    margin: 1rem inherit;
    margin-top: 1rem;
  }
  .contacts,
  .files,
  .employees {
    height: 24rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .email-report-form .form-textarea {
    height: 360px;
  }
  .logo-large {
    width: 280px;
  }
  .details {
    padding-right: 0.5%;
  }
  .header-address {
    float: right;
    line-height: 1.2rem;
    font-size: 0.8rem;
  }  
  .nav-center .btn-block {
    max-width: 10rem;
  }
  .account-dropdown {
    margin: 3rem;
    position: sticky;
    top: 90%;
  }
  .dropdown {
    position: relative;
    left: 0;
    width: 100%;
    background: var(--primary-100);
    box-shadow: var(--shadow-2);
    padding: 0.5rem;
    text-align: center;
    visibility: hidden;
    border-radius: var(--borderRadius);
  }
  .show-dropdown {
    visibility: visible;
  }
  .dropdown-btn {
    background: transparent;
    border-color: transparent;
    color: var(--primary-500);
    letter-spacing: var(--letterSpacing);
    text-transform: capitalize;
    cursor: pointer;
  }
  .user-name {
    margin: 0.5rem 1rem;
  }
  .file-upload {
    
    input {
      display: none;
    }
    .label-button {
      width:100%;
      text-align:center;
    }
    color: green;
    margin-top: 1rem;
  }
  .work-specs .form-center .align-button {
    margin-top: 1.72rem;
  }
  .work-specs .btn-block {
    margin-top: 1.5rem;
  }  
  .user-settings .align-button {
    margin-top: 2.22rem;
  }
  .deposit .money .value {
    font-size: var(--text-small);
  }
  .warranties .checkbox .text-box {
    max-width: 100%
  }
  .selected-photo {
    border: .2em solid var(--primary-500);
  }  
  .flex-parent .image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 212px;
  }
  .rbc-calendar {
    font-size: var(--text-smaller);
    line-height: 1rem;
  }
  .upcoming-appts-group {
    max-height: 500px;
    overflow-y: scroll;
  }
  .upcoming-appt {
    background-color: var(--primary-25);
  }
  .upcoming-appt:nth-child(even) {
    background-color: var(--primary-50);
  }
  .upcoming-appts-group .chosen-appt {
    background-color: var(--white);
    border: 0px groove var(--primary-600);
  }
  .appt-number {
    margin: .25rem 0 0 1rem;
  }
  .chosen-appt .appt-number {
    color: var(--primary-600);
  }
  .appt-description {
    margin: .5rem 0 .5rem 1rem;
  }
  .map-modal {
    font-size: var(--text-small);
  }
  .map-modal h5 {
    font-size: var(--text-medium);
    margin: .5rem 0 0;
  }
  .map-modal p {
    margin: 0 0 .5rem;
  }
  .map-modal-title {
    background-color: var(--primary-300);
    color: white;
    font-size: 1.5rem;
    max-width: 2rem;
    line-height: 2rem;
    text-align: center;
    border-radius: 2rem;
    font-weight: bold;
    margin: -.2rem 0 .5rem;
  }
  .map-icon {
    color: var(--primary-600);
    width: 2rem;
    height: 2rem;
    margin: 0 1rem
  }
  /* ----- Modal Form Boxes ----- */

  .modal-container {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: var(--transition);
    overflow: scroll;
  }
  .show-modal {
    z-index: 90;
    opacity: 1;
  }
  .show-modal .group {
    margin: 2%;
    padding: 1%;
  }
  .show-modal .close-btn {
    position: relative;
    top: -.5rem;
    margin-right: 1rem;
    background: transparent;
    border-color: transparent;
    font-size: 2rem;
    color: var(--red-dark);
    cursor: pointer;
    display: block;
    margin-left: auto;
  }
  .show-modal button {
    align-self: start;
  }
  .show-modal .bottom-buttons {
    margin-top: 3rem;
  }
  .email-report-form .checkbox .form-row {
    margin-bottom: 0;
  }
  .show-modal .btn-container {
    margin-top: 2rem;
  }

  /* ----- Tables ----- */

  width: 100%;
  table {
    width: 100%;
    border: 1px solid #c0c0c0;
    border-collapse: collapse;
    padding: 1em;
    margin-bottom: 0;
  }
  th {
    border: 1px solid var(--primary-400);
    padding: 5px;
    background: var(--primary-500);
    color: var(--grey-100);
  }
  td {
    border: 1px solid #c0c0c0;
    padding: 5px;
  }
  tr {
    background: var(--grey-100);
  }
  tr:nth-child(even) {
    background: var(--grey-50);
  }
  tr.selected {
    background: var(--primary-300);
    color: var(--grey-100);
  }
  thead {
    position: sticky;
    top: 3.7rem;
  }
  .scroll {
    overflow: scroll;
    height: 313px;
  }
  .price-sheets input {
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  /* ----- Screesize Dependent Styling ----- */

  @media (max-width: 1280px) {
    .mobile-no-show {
      display: none;
    }
    .form .mobile-whole {
      min-width:90%
    }
    .page {
      font-size: var(--text-small);
    }
    .group {
      padding: 0.5rem 0.5rem 1rem;
      margin: 0.5rem 0.3rem;
    }
    .form {
      // width: 91vw;
    }
    .form-row {
      margin-bottom: .6rem;
    }
    .nav-center .form-row {
      margin-bottom: 0;
    }  
    .one-fourth {
      min-width: 0;
    }
    .appointments .flex-parent .one-half {
      min-width: 220px;
    }
    .appointments .btn-container .one-half {
      min-width: 100px;
    }
    .sub-section {
      padding: 0;
    }
    table {
      font-size: var(--text-extra-small);
    }
    thead {
      position: sticky;
      top: 8.02rem;
    }
    .btn-block {
      font-size: var(--text-extra-small);
    }
    .select-job {
      margin: 0 0;
    }
    .toggle-btn {
      margin: 0.5rem auto;
      padding-top: 0.5rem;
    }
    .header-btn {
      margin: 0.5rem 0;
    }
    .header-btn .btn {
      width: 100%;
    }
    .modal-container {
      inset: 0;
      justify-content: center;
      align-items: baseline;
      transition: var(--transition);
      overflow: scroll;
    }
    .modal-container .form {
      width: 92vw;
    }
    .show-modal .group {
      margin: 2%;
      padding: 2%;
    }
    .photo-modal .flex-parent {
      column-gap: .25rem;
    }
    .photo-modal .three-fourths {
      min-width: 300px;
    }
    .money .one-half {
      max-width: 7rem;
    }
    .report .money .one-half {
      max-width: 100%;
    }
    .mobile-only .nav-center {
      padding: 0rem 0.25rem;
      display: flex;
      column-gap: 1.5rem;
      align-content: center;
    }
    .work-specs .form-center .align-button {
      margin-top: 1.54rem;
    }
    .work-specs .btn-block {
      margin-top: .8rem;
    }
    .upcoming-appt {
      font-size: var(--text-extra-small);
    }
    .dashboard .upcoming-jobs {
      padding: 0;
    }
    .rbc-calendar {
      font-size: var(--text-extra-small);
      max-width: 93vw;
    }
    .rbc-toolbar-label {
      line-height: 1.75em;
    }
    .dashboard .map {
      margin: 0;
      padding: 0;
    }
    .map-modal {
      font-size: var(--text-extra-small);
    }
    .map-modal h5 {
      font-size: var(--text-smaller);
      margin: .5rem 0 0;
    }
    .map-modal p {
      margin: 0 0 .5rem;
    }
    .map-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 .5rem
    }
    .map-modal-title {
      font-size: 1.5rem;
      max-width: 2rem;
      line-height: 2rem;
      border-radius: 2rem;
      font-weight: bold;
      margin: -.4rem 0 .25rem;
    }
  }
  @media (min-width: 1280px) {
    .mobile-only {
      display: none;
    }
    .page {
      column-gap: .8rem;
      padding: .4rem .8rem 0
    }
    .group {
      padding: .75rem 1rem 1rem;
      margin: .5rem .1rem;
    }
    .form-center {
      align-items: top;
      column-gap: 1rem;
    }
    .btn-container {
      margin-top: 0;
    }
    .dashboard {
      /* grid-template-columns: 1fr; */
    }
    .dashboard-page {
      width: 98%;
    }
    .group .email-report-form {
      width: 920px;
    }
  }
  @media (max-width: 1400px) {
    .work-specs .subform .display {
      font-size: var(--text-medium);
      margin: 0.5rem 0.25rem;
    }
    .work-specs .subform .details .value {
      font-size: var(--text-extra-small);
      line-height: 1rem;
    }
  }
`;

export default Wrapper;
