import { useAppContext } from "../../context/appContext";
import { BsClipboardData } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router-dom";

function UpcomingJobs({ chosenAppt, setChosenAppt, MapLink }) {
  const { calendarAppts, lists, loadMainRecords } = useAppContext();
  const navigate = useNavigate();

  const today = new Date().setHours(4); // start of day
  const maxTime = new Date();
  maxTime.setDate(maxTime.getDate() + 14);
  const hour = 60 * 60 * 1000;
  const day = hour * 24;

  const getDuration = (minTime, maxTime) => {
    let difference = maxTime - minTime;
    let duration;
    if (difference > day) {
      difference = Math.ceil(difference / day);
      duration = `${difference} days`;
    } else {
      difference = Math.ceil(difference / hour);
      duration = difference !== 1 ? `${difference} hours` : "1 hour";
    }
    return duration;
  };

  const formatAddress = (address) => {
    const addressRegex = /(.+?), (.*)/;
    const addressMatch = address?.match(addressRegex);
    let raw, street, cityStateZip;
    if (addressMatch) [raw, street, cityStateZip] = addressMatch;
    return { street, cityStateZip };
  };

  const handleClick = async (event) => {
    console.log("event:", event);
    console.log("event.title:", event.title);
    console.log("event.jobId:", event.jobId);
    if (event.jobId) {
      loadMainRecords(event.jobId);
      setTimeout(() => {
        navigate("../job-summary");
      }, 500);
    }
  };

  const JobLink = ({ event }) => {
    return (
      <div onClick={() => handleClick(event)}>
        <IconContext.Provider value={{ className: "map-icon" }}>
          <BsClipboardData />
        </IconContext.Provider>
      </div>
    );
  };

  const selectAppt = (appt) => {
    setChosenAppt(appt);
  };

  return (
    <div className="group one-half">
      <form className="form">
        <div className="form-center">
          <div className="whole upcoming-appts-group">
            {calendarAppts?.map((appt, i) => {
              if (appt?.end >= today && appt?.start <= maxTime) {
                return (
                  <div
                    key={appt.id}
                    className={
                      chosenAppt?.id === appt.id
                        ? "chosen-appt flex-parent upcoming-appt"
                        : "flex-parent upcoming-appt"
                    }
                    onClick={() => selectAppt(appt)}
                  >
                    <h3 className="appt-number">{appt.listNumber}</h3>
                    <div className="one-third">
                      <div>
                        <b>
                          {appt.start.toDateString()}
                          {!appt.allDay &&
                            " " + appt.start.toLocaleTimeString("en-US")}
                          {" - "}
                          {getDuration(appt.start, appt.end)}
                        </b>
                      </div>
                      <div>
                        <i>{appt.title}</i>
                      </div>
                    </div>
                    <div className="one-third flex-parent">
                      <div className="two-thirds">
                        {formatAddress(appt.resource).street}
                        <br />
                        {formatAddress(appt.resource).cityStateZip}
                      </div>
                      {chosenAppt?.id === appt.id && appt.resource && (
                        <div className="one-third">
                          <MapLink address={appt.resource} />
                          <JobLink event={appt} />
                        </div>
                      )}
                    </div>
                    {chosenAppt?.id === appt.id && (
                      <div className="appt-description one-half">
                        {appt.desc}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </form>
    </div>
  );
}
export default UpcomingJobs;
