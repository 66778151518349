import { Input } from "../workspace";
import { useAppContext } from "../../context/appContext";

function NewAddress() {
  const {
    handleChange,
    newJob,
    newAddress,
    displayAlert,
    isLoading,
    saveRecord,
    createRecord,
    newCustomer,
  } = useAppContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newAddress.street_address) {
      displayAlert("Please include a street address.");
      return;
    }

    if (!newAddress.id) {
      const record = await createRecord(
        "/address/create",
        newAddress,
        "Address"
      );
      handleChange({
        baseObject: { ...newAddress },
        name: "newAddress.id",
        value: record.id,
      });
      handleChange({
        baseObject: { ...newJob },
        name: "newJob.address_id",
        value: record.id,
      });
    } else {
      saveRecord(`/address/update/${newAddress.id}`, newAddress, "Address");
    }
  };

  const handleInput = (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
  };
  return (
    <div name="newAddress" className="group one-third">
      {newCustomer.id ? (
        <>
          <form onSubmit={handleSubmit} className="form">
            <h3>Address</h3>
            <div className="form-center">
              <div className="whole">
                <Input
                  type="text"
                  name="newAddress.street_address"
                  labelText="Street"
                  value={newAddress.street_address || ""}
                  handleChange={(e) => handleInput(e, { ...newAddress })}
                />
              </div>
              <div className="whole">
                <Input
                  type="text"
                  name="newAddress.city"
                  labelText="City"
                  value={newAddress.city || ""}
                  handleChange={(e) => handleInput(e, { ...newAddress })}
                />
              </div>
              <div className="one-half">
                <Input
                  type="text"
                  name="newAddress.zip"
                  labelText="Zip Code"
                  value={newAddress.zip || ""}
                  handleChange={(e) => handleInput(e, { ...newAddress })}
                />
              </div>
              <div className="one-half">
                <Input
                  type="text"
                  name="newAddress.state"
                  labelText="State"
                  value={newAddress.state || ""}
                  handleChange={(e) => handleInput(e, { ...newAddress })}
                />
              </div>
              {
                <button
                  name="saveAddress"
                  className="btn btn-block"
                  type="submit"
                  disabled={isLoading}
                >
                  {!newAddress.id ? "Add Address" : "Save Address"}
                </button>
              }
            </div>
          </form>
        </>
      ) : (
        <>
          <h3>Please select a customer to continue...</h3>
        </>
      )}
    </div>
  );
}
export default NewAddress;
