import { useState, useRef } from "react";
import { useAppContext } from "../../context/appContext";
import { FaTimes } from "react-icons/fa";
import FileUpload from "./PhotoModal/FileUpload";
import AddMethodButton from "./PhotoModal/AddMethodButton";
import { iterateName, getExtension } from "../../utils/fileNames";
import convert from "heic-convert/browser";

function PhotoModal({ name, setShowPhotoModal, beforeAfter }) {
  const {
    job,
    files,
    photos,
    handlePrimativeChange,
    createRecord,
    uploadFile,
    getDimensions,
    isLoading,
  } = useAppContext();

  const addMethods = ["folder", "upload", "camera"];
  const [addMethod, setAddMethod] = useState(addMethods[0]);
  const [selectedPhotos, setSelecetPhotos] = useState([]);
  const [fileMetadata, setFileMetadata] = useState(null);
  const newFile = useRef(null);
  const [newName, setNewName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const imageTypes = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

  const getPhotos = () => {
    let photosOnly = [];
    let uniquePhotos = [];

    if (files.length > 0) {
      photosOnly = files?.filter((file) =>
        imageTypes.some((ext) => file.name.includes(ext))
      );
    }
    if (photosOnly.length > 0) {
      uniquePhotos = photosOnly.filter((photo) => {
        return !photos?.some((p) => {
          return p.photo_name === photo.name;
        });
      });
    }
    return uniquePhotos;
  };

  const cleanup = () => {
    // Clean up
    setSelecetPhotos([]);
    setFileMetadata(null);
    setAddMethod(addMethods[0]);
    setNewName("");
    setImagePreview(null);
    // Close form
    setShowPhotoModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isBefore = beforeAfter === "Before";
    let newPhotoNames = [];
    let uploadedFile;
    const uniquePhotos = getPhotos();
    // Method 1 - Add files from job folder
    if (addMethod === addMethods[0]) {
      newPhotoNames = selectedPhotos.map((i) => uniquePhotos[i].name);
    }
    // Method 2 - Upload new photo & Method 3 - Take photo with camera
    if (addMethod === addMethods[1] || addMethod === addMethods[2]) {
      const jobPath = job.folder_path;
      const fileExtension = getExtension(fileMetadata.name);
      const fileName = newName + fileExtension;
      newFile.current = new File([newFile.current], fileName, {
        type: newFile.current.type,
      });
      // Save file and update newPhotosNames
      console.log("newFile.current:", newFile.current);
      uploadedFile = await uploadFile({ file: newFile.current, jobPath });
      newPhotoNames.push(fileName);
    }
    // Build out the full photo object for each of the names
    const newPhotos = await Promise.all(
      newPhotoNames.map(async (fileName) => {
        try {
          const photo_name = fileName.replace(".heic", ".jpg");
          const job_id = job.id;
          const category = isBefore ? "Before Photos" : "After Photos";
          const filePath = `${job.folder_path}/${photo_name}`;
          const { width, height } = await getDimensions(filePath);

          let newPhoto = { photo_name, job_id, category, width, height };

          // Add record to database
          newPhoto = await createRecord(
            `/photos/create`,
            newPhoto,
            "before/after photo"
          );
          return newPhoto;
        } catch (error) {
          console.error("Error adding photo:", fileName, error);
          // Return null to indicate failure and allow the map function to continue
          return null;
        }
      })
    );
    // Filter out any null values if any
    const successfulPhotos = newPhotos.filter((photo) => photo !== null);
    // Update state with new selected photos
    const addPhotos = [...photos, ...successfulPhotos];
    handlePrimativeChange({
      property: "photos",
      value: addPhotos,
    });
    // Clean up
    cleanup();
  };

  const handlePhotoClick = (index) => {
    let newSelections = [];
    if (selectedPhotos.includes(index)) {
      newSelections = selectedPhotos.filter((i) => i !== index);
    } else {
      newSelections = [...selectedPhotos, index];
    }
    setSelecetPhotos(newSelections);
  };

  const handleFileInput = async (e) => {
    console.log("file to upload:", e.target.files[0]);
    const file = e.target.files[0];
    newFile.current = file; // used to store the actual file object
    const reader = new FileReader();
    if (file) {
      // Load preview image of photo
      if (file.name.includes(".heic")) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }

      reader.onloadend = async () => {
        let convertedBuffer = reader.result;
        console.log("convertedBuffer:", convertedBuffer);
        if (file.name.includes(".heic")) {
          const buffer = new Uint8Array(convertedBuffer);
          convertedBuffer = await convert({
            buffer: buffer, // the HEIC file buffer
            format: "JPEG", // output format
            quality: 0.9, // the jpeg compression quality, between 0 and 1
          });
          // Convert the ArrayBuffer to a data URL string for preview
          const blob = new Blob([convertedBuffer], { type: "image/jpeg" });
          convertedBuffer = URL.createObjectURL(blob);
        }
        setImagePreview(convertedBuffer);
      };

      // Determine and set new file name
      const baseFileName = `${job.job_number} ${beforeAfter} Photo`;
      const fileName = iterateName(baseFileName, files);
      setNewName(fileName);
      // Add Dimensions
      setFileMetadata(file);
    }
  };

  const handleCancel = (e) => {
    console.log("action cancelled...");
    cleanup();
  };

  return (
    <form className="form photo-modal">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={handleCancel}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <h3>{`Add new ${beforeAfter} photo`}</h3>
        <div className="btn-container flex-parent whole">
          {/* Add from Job Folder Button - Method 1 */}
          <AddMethodButton
            addMethod={addMethod}
            method={addMethods[0]}
            buttonLabel="Job Folder"
            setAddMethod={setAddMethod}
            inputType="button"
          />
          {/* Upload File Button - Method 2 */}
          <AddMethodButton
            accept="image/*"
            addMethod={addMethod}
            method={addMethods[1]}
            buttonLabel="Upload File"
            handleFileInput={handleFileInput}
            inputType="file"
            setAddMethod={setAddMethod}
          />
          {/* Use Camera - Method 3 */}
          <AddMethodButton
            addMethod={addMethod}
            method={addMethods[2]}
            buttonLabel="Use Camera"
            capture={true}
            handleFileInput={handleFileInput}
            inputType="file"
            setAddMethod={setAddMethod}
          />
        </div>
        {/* Method 1 - Add from Job Folder - List of Files Section */}
        {addMethod === addMethods[0] && (
          <div className="flex-parent whole">
            {getPhotos().map((file, i) => {
              const thumbnail = file.thumbnails[0]?.medium;
              return (
                <img
                  src={thumbnail?.url}
                  key={i}
                  className={
                    selectedPhotos.includes(i)
                      ? "selected-photo thumbnail"
                      : "thumbnail"
                  }
                  height={thumbnail.height}
                  width={thumbnail.width}
                  alt={file.name}
                  onClick={() => handlePhotoClick(i)}
                />
              );
            })}
          </div>
        )}
        {/* Method 2 - Upload New File - File Info Section */}
        {addMethod === addMethods[1] && (
          <FileUpload
            imagePreview={imagePreview}
            fileMetadata={fileMetadata}
            newName={newName}
            setNewName={setNewName}
          />
        )}
        {/* Method 3 - Use Camera to Tak Photo - File Info Section */}
        {addMethod === addMethods[2] && (
          <FileUpload
            imagePreview={imagePreview}
            fileMetadata={fileMetadata}
            newName={newName}
            setNewName={setNewName}
          />
        )}
      </div>

      {/* Submit/ Clear Buttons */}
      <div className="btn-container flex-parent whole">
        <div className="one-half">
          <button
            name={name}
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "please wait..." : "confirm"}
          </button>
        </div>
        <div className="one-half">
          <button
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={handleCancel}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default PhotoModal;
