import { useAppContext } from "../../context/appContext";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";

function JobsCalendar({ chosenAppt, setChosenAppt }) {
  const localizer = dayjsLocalizer(dayjs);
  const { calendarAppts } = useAppContext();

  const handleSelectAppt = (appt) => {
    setChosenAppt(appt);
    console.log("Selected Appointment:", appt);
  };

  const eventPropGetter = (event) => {
    if (event === chosenAppt) {
      return { style: { backgroundColor: "#4d93ff" } };
    }
    return {};
  };

  return (
    <div className="group one-half">
      <form className="form">
        <Calendar
          localizer={localizer}
          events={calendarAppts || []}
          startAccessor="start"
          endAccessor="end"
          // eventPropGetter={eventPropGetter}
          onSelectEvent={handleSelectAppt}
          min={new Date(2024, 12, 14, 7, 0, 0)} // Set min time to 7 AM
          max={new Date(2024, 12, 14, 20, 0, 0)} // Set max time to 8 PM
          style={{ height: 500 }}
          selected={chosenAppt}
        />
      </form>
    </div>
  );
}
export default JobsCalendar;
