import { useAppContext } from "../../context/appContext";
import { TextArea } from "../../components/workspace";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function Charts() {
  const localizer = dayjsLocalizer(dayjs);
  const { job, displayAlert, handleChange, saveRecord, calendarAppts } =
    useAppContext();

  const today = new Date();
  const maxTime = new Date();
  maxTime.setDate(maxTime.getDate() + 14);
  const hour = 60 * 60 * 1000;
  const day = hour * 24;

  const getDuration = (minTime, maxTime) => {
    let difference = maxTime - minTime;
    let duration;
    if (difference > day) {
      difference = Math.ceil(difference / day);
      duration = `${difference} days`;
    } else {
      difference = Math.ceil(difference / hour);
      duration = `${difference} hours`;
    }
    return duration;
  };

  const formatAddress = (address) => {
    const addressRegex = /(.+?), (.*)/;
    const addressMatch = address?.match(addressRegex);
    let raw, street, cityStateZip;
    if (addressMatch) [raw, street, cityStateZip] = addressMatch;
    return { street, cityStateZip };
  };

  const handleInput = (e) => {
    const name = e.target.name;
    const value =
      name === "job.office_job_notes" ? e.target.value : Number(e.target.value);
    handleChange({ baseObject: job, name, value });
  };

  return (
    <div className="page">
      {/* ----- Calendar ------ */}
      <Calendar />
      {/* ----- Upcoming Jobs ------ */}
      <div className="group one-half">
        <form className="form">
          <h3>Upcoming Jobs</h3>
          <div className="form-center">
            <div className="whole upcoming-jobs">
              {calendarAppts?.map((appt, i) => {
                if (appt?.end >= today && appt?.start <= maxTime) {
                  return (
                    <div key={appt.id} className="flex-parent">
                      <div className="one-half">
                        <div>
                          <b>
                            {appt.start.toDateString()}
                            {" - "}
                            {getDuration(appt.start, appt.end)}
                          </b>
                        </div>
                        <div>
                          <i>{appt.title}</i>
                        </div>
                      </div>
                      <div className="one-half flex-parent">
                        <div className="whole">
                          {formatAddress(appt.resource).street}
                        </div>
                        <div className="whole">
                          {formatAddress(appt.resource).cityStateZip}
                        </div>
                      </div>
                      {appt.desc}
                      {appt?.gps?.lat},{appt?.gps?.lng}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </form>
      </div>
      {/* ----- Map ------ */}
      <div className="group one-half">
        <form className="form">
          <h3>Map</h3>
          <div className="form-center">
            <div className="whole">
              <TextArea
                name="job.office_job_notes"
                labelText="Job Notes"
                value={job.office_job_notes || ""}
                handleChange={(e) => handleInput(e)}
              />
            </div>
          </div>
        </form>
      </div>
      {/* ----- Summary Reports ------ */}
      <div className="group one-half">
        <form className="form">
          <h3>Summary Reports</h3>
          <div className="form-center">
            <div className="whole">
              <TextArea
                name="job.office_job_notes"
                labelText="Job Notes"
                value={job.office_job_notes || ""}
                handleChange={(e) => handleInput(e)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Charts;
