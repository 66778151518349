import React, { useReducer, useContext } from "react";
import reducer from "./reducer";
import axios from "axios";

import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  GET_LISTS,
  GET_DEFAULTS,
  GET_PRICES,
  CREATE_JOB_BEGIN,
  CREATE_JOB_ERROR,
  CREATE_JOB_SUCCESS,
  HANDLE_CHANGE,
  HANDLE_CHANGES,
  HANDLE_ARRAY_CHANGE,
  HANDLE_ARRAY_CHANGES,
  HANDLE_PRIMATIVE_CHANGE,
  SAVE_RECORD_BEGIN,
  SAVE_RECORD_ERROR,
  SAVE_RECORD_SUCCESS,
  GET_RECORD_BEGIN,
  GET_RECORD_ERROR,
  GET_RECORD_SUCCESS,
  GET_ARRAY_RECORD_SUCCESS,
  DELETE_RECORD_BEGIN,
  DELETE_RECORD_ERROR,
  DELETE_RECORD_SUCCESS,
  PROCESS_REPORT_BEGIN,
  PROCESS_REPORT_ERROR,
  PROCESS_REPORT_SUCCESS,
  FILE_UPLOAD_BEGIN,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_SUCCESS,
  CLEAR_CHANGES,
  CLEAR_ARRAY_CHANGES,
  TOGGLE_CONFIRM_BOX,
  TOGGLE_REPORT_FORM,
} from "./actions";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

const initialState = {
  version: "1.28.3",
  isLoading: false,
  serverProcessing: false,
  showAlert: false,
  alertText: "",
  alertType: "",
  user: user ? JSON.parse(user) : null,
  token: token,
  showSidebar: false,
  editItem: { show: false, index: 0 },
  newJob: {},
  newAddress: { state: "TX" },
  newCustomer: { roofer_discount: false, lead_id: "default" },
  newContacts: [],
  job: {},
  address: {},
  customer: {},
  contacts: [],
  files: [],
  photos: [],
  appointments: [],
  payments: [],
  options: [],
  email: {
    emailBody: "",
    emailSubject: "",
    emailReceivers: [],
    sendEmail: false,
    acraliteWarranty: false,
    veluxWarranty: false,
    freeliteWarranty: false,
    paymentLink: "",
  },
  report: {
    type: "",
    status: "",
    createReport: false,
  },
  calendar: {
    eventTitle: "",
    eventDescription: "",
    eventStart: null,
    eventEnd: null,
    eventAttendees: null,
    eventAddress: "",
    googleId: null,
    sendEvent: false,
  },
  calendarAppts: [],
  indices: {
    appointmentsIndex: null,
    paymentsIndex: null,
    optionsIndex: null,
  },
  fees: [],
  discounts: [],
  warranties: [],
  jobItems: [],
  employees: [],
  lists: {},
  defaults: {},
  prices: {},
  reportProgress: {
    createPDF: null,
    uploadPDF: null,
    emailCustomer: null,
    updateCalendar: null,
  },
  customerMatchInfo: {},
  showConfirmBox: false,
  showReportForm: false,
  changeUserMode: false,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userMode = JSON.parse(localStorage.getItem("user", user))?.user_mode;
  const authFetch = axios.create({
    baseURL: "/api/v1",
    headers: { Mode: `${userMode}` },
  });
  // request
  authFetch.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${state.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // response
  authFetch.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("response error:", error);
      if (error.response?.status === 401) {
        console.log("AUTH ERROR");
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  const displayAlert = (message) => {
    dispatch({ type: DISPLAY_ALERT, payload: { message: message } });
    clearAlert();
  };
  const clearAlert = (alertLength = 3000) => {
    setTimeout(() => {
      dispatch({ type: CLEAR_ALERT });
    }, alertLength);
  };
  const toggleSidebar = () => {
    dispatch({ type: TOGGLE_SIDEBAR });
  };
  const addUserToLocalStorage = ({ user, token }) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
  };
  const removeUserFromLocalStorage = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };
  const loginUser = async (currentUser) => {
    dispatch({ type: LOGIN_USER_BEGIN });
    try {
      const response = await authFetch.post("/auth/login", currentUser);
      const { user, token } = response.data;
      const userInfo = { ...user, user_mode: 0 };
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: { user: userInfo, token },
      });
      addUserToLocalStorage({ user: userInfo, token });
    } catch (error) {
      console.log(error.response);
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const logoutUser = () => {
    dispatch({ type: LOGOUT_USER });
    removeUserFromLocalStorage();
  };
  // ----- Global Record Changes (changes to db) -----
  const createRecord = async (path, tableObject, tableName) => {
    dispatch({ type: SAVE_RECORD_BEGIN });
    try {
      const { data } = await authFetch.post(path, tableObject);
      dispatch({
        type: SAVE_RECORD_SUCCESS,
        payload: { msg: `${tableName} created successfully` },
      });
      return data[0];
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: SAVE_RECORD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const saveRecord = async (path, tableObject, tableName) => {
    dispatch({ type: SAVE_RECORD_BEGIN });
    try {
      await authFetch.patch(path, tableObject);
      dispatch({
        type: SAVE_RECORD_SUCCESS,
        payload: { msg: `${tableName} saved successfully` },
      });
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: SAVE_RECORD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const getRecord = async (path, baseName, tableName) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      const { data } = await authFetch(path);
      // console.log("got data:", data[tableName])
      dispatch({
        type: GET_RECORD_SUCCESS,
        payload: { baseObject: data[tableName], baseName },
      });
      return data[tableName];
    } catch (error) {
      console.log("\n\nGet Record error...", error);
      console.log("path:", path);
      console.log("baseName:", baseName);
      console.log("tableName:", tableName);
      if (error.response?.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const getArrayRecord = async (path, baseName) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      const { data } = await authFetch(path);
      if (baseName === "appointments" || baseName === "payments") {
        // change to date formats to cooperate with the date input in html
        data.rows = data.rows.map((record) => {
          const myDate = (
            baseName === "appointments" ? record.appt_date : record.payment_date
          )
            .toString()
            .slice(0, 10);
          baseName === "appointments"
            ? (record.appt_date = myDate)
            : (record.payment_date = myDate);
          return record;
        });
      }
      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: data["rows"], baseName },
      });
      return data["rows"];
    } catch (error) {
      console.log("\n\nGet Record error...", error);
      console.log("path:", path);
      console.log("baseName:", baseName);
      if (error.response?.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: { msg: error.response?.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const deleteRecord = async (path) => {
    dispatch({ type: DELETE_RECORD_BEGIN });
    try {
      const response = await authFetch.delete(path);
      console.log("Delete response:", response.data.msg);
      dispatch({
        type: DELETE_RECORD_SUCCESS,
        payload: { msg: response.data.msg },
      });
      return response;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: DELETE_RECORD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  // ----- Global State Changes (local instances of objects) -----
  const handleChange = ({ baseObject, name, value }) => {
    const baseName = name.split(".")[0];
    name = name.split(".")[1];
    dispatch({
      type: HANDLE_CHANGE,
      payload: { baseObject, baseName, name, value },
    });
  };
  const handleChanges = ({ baseName, baseObject, newValues }) => {
    dispatch({
      type: HANDLE_CHANGES,
      payload: { baseName, baseObject, newValues },
    });
  };
  const handleArrayChange = ({
    baseName,
    baseArray,
    index,
    property,
    value,
  }) => {
    dispatch({
      type: HANDLE_ARRAY_CHANGE,
      payload: { baseName, baseArray, index, property, value },
    });
    // console.log("customer:", state.customer)
  };
  const handleArrayChanges = ({ baseName, baseArray, index, newValues }) => {
    dispatch({
      type: HANDLE_ARRAY_CHANGES,
      payload: { baseName, baseArray, index, newValues },
    });
    // console.log("customer:", state.customer)
  };
  const handlePrimativeChange = ({ property, value }) => {
    dispatch({ type: HANDLE_PRIMATIVE_CHANGE, payload: { property, value } });
  };
  const clearChanges = ({ baseName, defaultState }) => {
    // console.log("baseName:", baseName)
    dispatch({ type: CLEAR_CHANGES, payload: { baseName, defaultState } });
  };
  const clearArrayChanges = ({ baseName, defaultState }) => {
    // console.log("baseName:", baseName)
    dispatch({
      type: CLEAR_ARRAY_CHANGES,
      payload: { baseName, defaultState },
    });
  };
  // ----- specific functions (repeated use or makes api call) -----
  const getDefaults = async () => {
    let emailTemplates = await authFetch("/defaults/email-msgs");
    emailTemplates = [...emailTemplates.data];
    let workDescs = await authFetch("/defaults/work-descs");
    workDescs = [...workDescs.data];
    let warranties = await authFetch("/defaults/warranties");
    warranties = [...warranties.data];
    let PAs = await authFetch("/defaults/pas");
    PAs = [...PAs.data];
    let fees = PAs.filter((priceAdj) => priceAdj.amount >= 0);
    let discounts = PAs.filter((priceAdj) => priceAdj.amount < 0);
    dispatch({
      type: GET_DEFAULTS,
      payload: {
        defaultWarranties: warranties,
        defaultWorkDescs: workDescs,
        defaultEmails: emailTemplates,
        defaultFees: fees,
        defaultDiscounts: discounts,
        defaultPAs: PAs,
      },
    });
  };
  // populates pick lists
  const getLists = async () => {
    try {
      let allLists = await authFetch("/lists/all");
      // static lists that have extra modifications
      const listWorkTypes = allLists.data.listWorkTypes.map((workType) => {
        const { id, text, job_type_id } = workType;
        return { id, text, job_type_id };
      });
      const listSkylightSizes = allLists.data.listSkylightSizes.map(
        (skylightSize) => {
          const { id, text, skylight_type } = skylightSize;
          return { id, text, skylight_type };
        }
      );
      const listFrameTypes = allLists.data.listFrameTypes.map((frameType) => {
        const { id, text, skylight_style } = frameType;
        return { id, text, skylight_style };
      });
      const listServiceReps = allLists.data.listServiceReps.map((employee) => {
        const { id, nickname } = employee;
        return { id, text: nickname };
      });
      const listAllServiceReps = allLists.data.listAllServiceReps.map(
        (employee) => {
          const { id, nickname } = employee;
          return { id, text: nickname };
        }
      );
      const listPriceSheets = allLists.data.listPriceSheets.map((frameType) => {
        const { id, price_sheet_version } = frameType;
        return { id, text: price_sheet_version };
      });
      // dynamic lists
      const allJobs = allLists.data.allJobs.map((job) => {
        // const { text: job_status } = job; // key: id,
        return { ...job }; // , job_status
      });
      const listJobNames = allLists.data.listJobNames.map((job) => {
        const { id, job_number, customer_name, street_address } = job;
        const jobName = `${job_number} | ${customer_name} | ${street_address}`;
        return { key: id, text: jobName };
      });
      const customerNames = allLists.data.customerNames.map((customer) => {
        const { id, customer_name } = customer;
        return { key: id, text: customer_name };
      });

      allLists = {
        ...allLists.data,
        listWorkTypes,
        listSkylightSizes,
        listFrameTypes,
        listServiceReps,
        listAllServiceReps,
        listPriceSheets,
        allJobs,
        listJobNames,
        customerNames,
      };
      dispatch({
        type: GET_LISTS,
        payload: allLists,
      });
      console.log("Getting ALL lists:", allLists);

      return listJobNames;
    } catch (error) {
      console.error(error);
    }
  };
  // get dynamic job lists
  const getJobLists = async () => {
    try {
      let listJobs = (await authFetch("/lists/all-jobs")).data;
      let listNames = (await authFetch("/lists/job-names")).data;
      let listCustomers = (await authFetch("/lists/customer-names")).data;
      const allJobs = listJobs.map((job) => {
        // const { text: job_status } = job; // key: id,
        return { ...job }; // , job_status
      });
      const listJobNames = listNames.map((job) => {
        const { id, job_number, customer_name, street_address } = job;
        const jobName = `${job_number} | ${customer_name} | ${street_address}`;
        return { key: id, text: jobName };
      });
      const customerNames = listCustomers.map((customer) => {
        const { id, customer_name } = customer;
        return { key: id, text: customer_name };
      });
      const allLists = {
        ...state.lists,
        allJobs,
        listJobNames,
        customerNames,
      };
      dispatch({
        type: GET_LISTS,
        payload: allLists,
      });
      // console.log("Getting dynamic lists:", listJobNames);

      return listJobNames;
    } catch (error) {
      console.error(error);
    }
  };
  // populate price lists
  const getPrices = async () => {
    let prices = await authFetch("prices/all");
    prices = prices.data;
    dispatch({
      type: GET_PRICES,
      payload: prices,
    });
  };
  const getWarranties = (path) => {
    const resetWarranties = (callback) => {
      clearArrayChanges({ baseName: "warranties", defaultState: [] });
      callback();
    };
    const fillWarranties = async () => {
      dispatch({ type: GET_RECORD_BEGIN });
      try {
        const { data } = !path.includes("undefined")
          ? await authFetch(path)
          : { data: { rows: [] } };
        // fill empty warranty slots with default values
        data.rows = state.defaults.defaultWarranties.map((defaultWrnty) => {
          const match = data.rows.find((wrnty) => {
            return wrnty.position === defaultWrnty.id;
          });
          if (match) return { ...match, useWarranty: true };
          const obj = {
            warranty: defaultWrnty.warranty,
            position: defaultWrnty.id,
          };
          return obj;
        });
        dispatch({
          type: GET_ARRAY_RECORD_SUCCESS,
          payload: { baseObject: data["rows"], baseName: "warranties" },
        });
      } catch (error) {
        if (error.response?.status === 401) return;
        dispatch({
          type: GET_RECORD_ERROR,
          payload: { msg: error.response?.data.msg },
        });
      } finally {
        clearAlert();
      }
    };
    resetWarranties(fillWarranties);
  };
  const getReportWarranties = async (path) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      const { data } = !path.includes("undefined")
        ? await authFetch(path)
        : { data: { rows: [] } };

      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: data["rows"], baseName: "warranties" },
      });
      return data["rows"];
    } catch (error) {
      if (error.response?.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: { msg: error.response?.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const getPriceAdjustments = async (path) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      const { data } = !path.includes("undefined")
        ? await authFetch(path)
        : { data: { rows: [] } };
      let loadedFees = data.rows.filter((priceAdj) => {
        return priceAdj.amount >= 0;
      });
      let loadedDiscounts = data.rows.filter((priceAdj) => {
        return priceAdj.amount < 0;
      });
      // fill empty priceAdj slots with default values
      loadedFees = state.defaults.defaultFees.map((fee, index) => {
        if (loadedFees[index]) {
          loadedFees[index].usePA = true;
          return loadedFees[index];
        }
        // fee.id = null
        return { ...fee, id: null }; // reset default id so it is not accidentally used
      });
      loadedDiscounts = state.defaults.defaultDiscounts.map(
        (discount, index) => {
          if (loadedDiscounts[index]) {
            loadedDiscounts[index].usePA = true;
            return loadedDiscounts[index];
          }
          // discount.id = 55
          return { ...discount, id: null };
        }
      );
      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: loadedFees, baseName: "fees" },
      });
      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: loadedDiscounts, baseName: "discounts" },
      });
      return { discounts: loadedDiscounts, fees: loadedFees };
    } catch (error) {
      console.log("\n\ngetPriceAdjustments error...", error);
      console.log("path:", path);
      if (error.response?.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: { msg: error.response?.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const getCurrentJobNumber = async (path) => {
    const res = await authFetch(path);
    const { job_number } = res.data;
    return job_number;
  };
  const updateUser = async (currentUser) => {
    try {
      const { data } = await authFetch.patch("/auth/updateUser", currentUser);
      console.log("data:", data);
    } catch (error) {
      console.log(error.response);
    }
  };
  const createJob = async () => {
    dispatch({ type: CREATE_JOB_BEGIN });
    try {
      const { newJob, newCustomer, newAddress } = state;
      // add customer and address ids
      const addJob = {
        ...newJob,
        customer_id: newCustomer.id,
        address_id: newAddress.id,
        price_sheets_id: state.lists.listPriceSheets[0].id,
        job_status_id: 2,
      };
      // create core-job record in database
      const createdJob = await authFetch.post("/core-job/create", addJob);
      const jobId = createdJob.data.id;
      // create job folder in file system
      const basePath = newJob.folder_path.slice(0, -11); // remove job number leaving base path
      const jobNumber = newJob.job_number;
      await authFetch.post("/files/createFolder", { basePath, jobNumber });
      // create the first option
      await authFetch.post("/options/create", {
        core_job_data_id: jobId,
        title: "Option 1",
      });
      // load newly created records and navigate to appointments page
      await loadMainRecords(jobId);
      getJobLists();
      dispatch({ type: CREATE_JOB_SUCCESS });
      return createdJob;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: CREATE_JOB_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const initialize = async () => {
    // load lists and set default values
    if (Object.keys(state.defaults).length === 0) {
      await getDefaults();
    }
    if (Object.keys(state.prices).length === 0) {
      await getPrices();
    }
    if (Object.keys(state.lists).length === 0) {
      await getLists();
    }
    if (state.employees.length === 0) {
      await getArrayRecord(`/auth/get-all`, "employees");
    }
    if (state.calendarAppts.length === 0) {
      await getCalendarAppts();
    }
  };
  const loadJobOptions = async (job) => {
    const jobOptions = await getArrayRecord(`/options/${job.id}`, "options");
    const selectedOption = job.options_id || jobOptions[0]?.id;
    getPriceAdjustments(`/price-adj/${selectedOption}`); // can handle null in path
    getWarranties(`/warranties/${selectedOption}`); // can handle null in path
    // if there is an existing option grab it's job items
    if (selectedOption) {
      getArrayRecord(`/job-items/${selectedOption}`, "jobItems");
    }
    // or empty out the job items array and add the dummy option
    else {
      clearArrayChanges({ baseName: "jobItems", defaultState: [] });
      clearArrayChanges({
        baseName: "options",
        defaultState: [{ core_job_data_id: job.id }],
      });
    }
    // set options index
    const optionIds = jobOptions.map((option) => option.id); // copy an array of just id's
    const optionsIndex = job.options_id ? optionIds.indexOf(job.options_id) : 0; // find the index of the selected option if present
    handleChange({
      baseObject: { ...state.indices },
      name: "indices.optionsIndex",
      value: optionsIndex,
    });
  };
  const loadMainRecords = async (jobId) => {
    // clear non-db state objects
    clearChanges({ baseName: "indices", defaultState: initialState.indices });
    clearChanges({ baseName: "email", defaultState: initialState.email });
    clearChanges({ baseName: "report", defaultState: initialState.report });
    clearChanges({ baseName: "calendar", defaultState: initialState.calendar });
    clearChanges({ baseName: "files", defaultState: initialState.files });
    clearChanges({ baseName: "newJob", defaultState: initialState.newJob });
    clearChanges({
      baseName: "newAddress",
      defaultState: initialState.newAddress,
    });
    clearChanges({
      baseName: "newCustomer",
      defaultState: initialState.newCustomer,
    });
    clearArrayChanges({
      baseName: "newContacts",
      defaultState: initialState.newContacts,
    });
    clearArrayChanges({
      baseName: "photos",
      defaultState: initialState.photos,
    });
    // load lists and set default values if necessary
    if (Object.keys(state.lists).length === 0) {
      console.log("getLists...");
      await getLists();
    }
    if (Object.keys(state.defaults).length === 0) {
      console.log("getDefaults...");
      await getDefaults();
    }
    // load main records
    const jobData = await getRecord(`/core-job/${jobId}`, "job", "job");
    console.log("jobData", jobData);
    getRecord(`/address/${jobData.address_id}`, "address", "address");
    await getJobFiles(jobData.folder_path);
    await getRecord(`/customer/${jobData.customer_id}`, "customer", "customer");
    await getArrayRecord(`/contact/${jobData.customer_id}`, "contacts");
    getArrayRecord(`/appointments/${jobData.id}`, "appointments");
    getArrayRecord(`/payments/${jobData.id}`, "payments");
    getArrayRecord(`/photos/${jobData.id}`, "photos");
    loadJobOptions(jobData);
  };
  // process reports (create report => upload report => email customer(if desired))
  const processReport = async ({ reportName }) => {
    dispatch({ type: PROCESS_REPORT_BEGIN });
    console.log("process report/appt...");
    try {
      const { job, email, calendar, report, appointments, files, indices } =
        state;
      const jobFolder = job.folder_path;
      const appIndex = indices.appointmentsIndex;
      const reportDate = report.reportDate;
      const userMode = state.user.user_mode;
      // create/upload/email if desired
      if (report.createReport) {
        const fileInfo = await authFetch.post(`/reports/create/${job.id}`, {
          jobFolder,
          reportName,
          email,
          reportDate,
          userMode,
        });
        // load the newly created report into the files list
        const { id, name, webUrl, size } = fileInfo.data;
        const baseName = "files";
        const baseArray = files.filter((file) => file.id !== id);
        const index = files.length;
        const newValues = { id, name, webUrl, size };
        handleArrayChanges({ baseName, baseArray, index, newValues });
      }
      // send appointment if needed
      let newAppt = appointments[appIndex];
      if (calendar.sendEvent) {
        const response = await authFetch.put(
          `/google/add-appointment`,
          calendar
        );
        // update state with google id
        const googleId = response.data.data.id;
        console.log("id:", googleId);
        newAppt = { ...newAppt, google_id: googleId };
        console.log("newAppt:", newAppt);
        saveRecord(
          `/appointments/update/${newAppt.id}`,
          newAppt,
          "Appointment"
        );
        const baseName = "appointments";
        const baseArray = appointments;
        const newValues = { ...newAppt };
        handleArrayChanges({ baseName, baseArray, index: appIndex, newValues });
      }
      dispatch({ type: PROCESS_REPORT_SUCCESS });
    } catch (error) {
      console.log("error:", error);
      if (error.response.status === 401) return;
      dispatch({
        type: PROCESS_REPORT_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
    clearAlert();
  };
  const getCustomerMatchInfo = async (customerId) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      // Get customer match
      const customerFetch = await authFetch(`/customer/${customerId}`);
      const matchingCustomer = customerFetch.data.customer;
      // Get matching contacts
      const contactsFetch = await authFetch(`/contact/${customerId}`);
      const matchingContacts = contactsFetch.data.rows;
      // get all jobs to find matching addresses
      const { data } = await authFetch(`/core-job/list-all`);
      const matchingJobs = data?.filter(
        (job) => job.customer_id === customerId
      );
      // build data pairs for matching addresses
      let matchingAddresses = await Promise.all(
        matchingJobs
          .filter((job) => job.address_id)
          .map(async (job) => {
            if (job.address_id) {
              const { data } = await authFetch(`/address/${job.address_id}`);
              const { id, street_address } = data["address"];
              return data && { key: id, text: street_address };
            }
          })
      );
      // remove duplicates
      matchingAddresses = matchingAddresses.filter((obj, index, self) => {
        return index === self.findIndex((e) => obj.key === e.key);
      });
      // Add none option to saved address
      matchingAddresses.push({ key: -1, text: "Don't use old address" });
      console.log("matchingAddresses:", matchingAddresses);
      // Build matches array
      const customerMatchInfo = {
        matchingCustomer,
        matchingContacts,
        matchingAddresses,
      };
      // complete action and assign state value
      if (matchingCustomer) {
        dispatch({
          type: GET_RECORD_SUCCESS,
          payload: {
            baseObject: customerMatchInfo,
            baseName: "customerMatchInfo",
          },
        });
      }
      return matchingAddresses;
    } catch (error) {
      if (error.response.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: { msg: error.response.data.msg },
      });
    } finally {
      clearAlert();
    }
  };
  const toggleConfirmBox = ({ confirmed, confirmedFunction }) => {
    if (confirmed) {
      // console.log("typeof confirmedFunction:", typeof confirmedFunction);
      confirmedFunction();
    }
    dispatch({ type: TOGGLE_CONFIRM_BOX });
  };
  const toggleReportForm = () => {
    if (state.showReportForm) {
      const { report, calendar, email } = initialState;
      clearChanges({ baseName: "report", defaultState: report });
      clearChanges({ baseName: "calendar", defaultState: calendar });
      clearChanges({ baseName: "email", defaultState: email });
    }
    dispatch({ type: TOGGLE_REPORT_FORM });
  };
  const uploadFile = async ({ file, jobPath }) => {
    dispatch({ type: FILE_UPLOAD_BEGIN });
    const basePath = "/files/upload";
    const formData = new FormData();

    formData.append("file", file);
    formData.append("jobPath", jobPath);

    console.log("attempting to upload...");
    try {
      const newFile = await authFetch.post(basePath, formData);
      await getJobFiles(jobPath);
      dispatch({
        type: FILE_UPLOAD_SUCCESS,
      });
      return newFile.data;
    } catch (error) {
      if (error.response?.status === 401) return;
      dispatch({
        type: FILE_UPLOAD_ERROR,
        payload: {
          msg:
            error.response?.data.msg || "There was a problem uploading files.",
        },
      });
    } finally {
      clearAlert();
    }
  };
  const getJobFiles = async (jobPath) => {
    dispatch({ type: GET_RECORD_BEGIN });
    try {
      const basePath = "/files";
      const files = await authFetch({
        url: basePath,
        method: "get",
        params: {
          jobPath,
        },
      });
      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: files.data, baseName: "files" },
      });
      return files.data;
    } catch (error) {
      if (error.response?.status === 401) return;
      dispatch({
        type: GET_RECORD_ERROR,
        payload: {
          msg:
            error.response?.data.msg ||
            "There was a problem downloading files.",
        },
      });
    } finally {
      clearAlert();
    }
  };
  const geocodeAddress = async (event, apptStart, apptEnd, apptNum) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    // Only geocode the appointments that happen soon and have an address
    const listNumber = apptNum;
    let gps = null;
    if (event.location) {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          event.location
        )}&key=${apiKey}`
      );
      if (response.data.results[0]) {
        const { lat, lng } = response.data.results[0].geometry.location;
        gps = { lat, lng };
      }
    }

    return { gps, listNumber };
  };
  const getCalendarAppts = async () => {
    dispatch({ type: GET_RECORD_BEGIN });
    let apptNum = 1;
    let listJobs = (await authFetch("/lists/all-jobs")).data;
    let gps = null;
    let listNumber = null;
    let jobId = null;
    const today = new Date().setHours(4); // start of day;
    const maxTime = new Date();
    maxTime.setDate(maxTime.getDate() + 14);

    const findMatchingJob = (event) => {
      let matchingJobs;
      const jobNumberRegex = /[A-Z]\d{2}-\d{2}-\d{3}/;
      const jobNumber = event.summary?.match(jobNumberRegex);
      if (jobNumber) {
        matchingJobs = listJobs?.filter(
          (job) => job.job_number === jobNumber[0]
        );
        // console.log("event.summary:", event.summary);
        // console.log("jobNumber:", jobNumber && jobNumber[0]);
        // console.log("matchingJobs:", matchingJobs);
      }
      if (matchingJobs?.length > 0) return matchingJobs[0].id;
    };

    try {
      const basePath = "/google/get-appointments";
      const appointments = await authFetch({
        url: basePath,
        method: "get",
      });

      // Convert appointment object from google's format to react-big-calendar
      const rbcAppts = [];
      for (const event of appointments.data) {
        // Format dates for start and end times
        const apptStart = event.start.dateTime
          ? new Date(event.start.dateTime)
          : new Date(event.start.date);
        const apptEnd = event.end.dateTime
          ? new Date(event.end.dateTime)
          : new Date(event.end.date);
        // Process upcoming appointments to display on the Dashboard page
        if (apptEnd >= today && apptStart <= maxTime) {
          // Geocode Address
          const addressInfo = await geocodeAddress(
            event,
            apptStart,
            apptEnd,
            apptNum
          );
          gps = addressInfo.gps;
          listNumber = addressInfo.listNumber;
          if (listNumber) apptNum += 1;
          // Searching for matching job record
          jobId = findMatchingJob(event);
        }
        // Return processed appointment
        rbcAppts.push({
          id: event.id,
          title: event.summary,
          start: apptStart,
          end: apptEnd,
          allDay: !event.start.dateTime, // If start.dateTime is not present, it's an all-day event
          resource: event.location,
          desc: event.description,
          gps,
          listNumber,
          jobId,
        });
      }

      console.log("Calendar Appts:", rbcAppts);
      dispatch({
        type: GET_ARRAY_RECORD_SUCCESS,
        payload: { baseObject: rbcAppts, baseName: "calendarAppts" },
      });
      return rbcAppts;
    } catch (error) {
      if (error.response?.status === 401) return;
      console.log("Error:", error);
      dispatch({
        type: GET_RECORD_ERROR,
        payload: {
          msg:
            error.response?.data.msg ||
            "There was a problem retrieving calendar appointments.",
        },
      });
    } finally {
      clearAlert();
    }
  };
  const getDimensions = async (filePath) => {
    try {
      const timeStart = new Date();

      const dimensions = await authFetch({
        url: "files/dimensions",
        method: "get",
        params: {
          filePath,
        },
      });

      const timeEnd = new Date();
      console.log("Get Dimension Time:", timeEnd - timeStart);

      return dimensions.data;
    } catch (error) {
      console.error("Error getting file dimensions:", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        initialState,
        handleChange,
        handleChanges,
        handleArrayChange,
        handleArrayChanges,
        handlePrimativeChange,
        displayAlert,
        toggleSidebar,
        loginUser,
        logoutUser,
        updateUser,
        clearChanges,
        clearArrayChanges,
        createJob,
        processReport,
        createRecord,
        saveRecord,
        getRecord,
        getArrayRecord,
        deleteRecord,
        getLists,
        getJobLists,
        getDefaults,
        getPrices,
        getWarranties,
        getReportWarranties,
        getPriceAdjustments,
        getCurrentJobNumber,
        getJobFiles,
        getCalendarAppts,
        uploadFile,
        getDimensions,
        initialize,
        loadMainRecords,
        loadJobOptions,
        getCustomerMatchInfo,
        toggleConfirmBox,
        toggleReportForm,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext, initialState };
