import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { TextArea } from "../../components/workspace";
import { FaWarehouse, FaMapMarkedAlt } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  JobsCalendar,
  UpcomingJobs,
  JobMap,
  Charts,
} from "../../components/Dashboard";

function Dashboard() {
  const { job, displayAlert, handleChange, saveRecord, calendarAppts } =
    useAppContext();

  const [chosenAppt, setChosenAppt] = useState(null);

  const MapLink = ({ address }) => {
    return (
      <a
        href={`https://www.google.com/maps/place/${address}/`}
        target="_blank"
        rel="noreferrer"
      >
        <IconContext.Provider value={{ className: "map-icon" }}>
          <FaMapMarkedAlt />
        </IconContext.Provider>
      </a>
    );
  };

  return (
    <div className="dashboard page">
      {/* ----- Job Map ------ */}
      <JobMap
        chosenAppt={chosenAppt}
        setChosenAppt={setChosenAppt}
        MapLink={MapLink}
      />
      {/* ----- Upcoming Jobs ------ */}
      <UpcomingJobs
        chosenAppt={chosenAppt}
        setChosenAppt={setChosenAppt}
        MapLink={MapLink}
      />
      {/* ----- Calendar ------ */}
      <JobsCalendar chosenAppt={chosenAppt} setChosenAppt={setChosenAppt} />
      {/* ----- Summary Reports ------ */}
      {/* <div className="group one-half">
        <form className="form">
          <h3>Chosen Option: {chosenAppt?.title}</h3>
          <h4>
            gps: {chosenAppt?.gps?.lat},{chosenAppt?.gps?.lng}
          </h4>
          <div className="form-center">
            <div className="whole">
              <TextArea
                name="job.office_job_notes"
                labelText="Job Notes"
                value={job.office_job_notes || ""}
                handleChange={() => setChosenAppt("1")}
              />
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
}
export default Dashboard;
