import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Input, Select } from "../workspace";

const AdminPriceTables = () => {
  const { lists, defaults, prices, handleChange, saveRecord, isLoading } =
    useAppContext();

  const [priceSheet, setPriceSheet] = useState(0);
  const [selectedRow, setSelectedRow] = useState();

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    console.log("name:", name);
    console.log("value:", value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // iterate through all default descriptions and save each one
    defaults.defaultWorkDescs.forEach((description) => {
      saveRecord(
        `/defaults/update-work-desc/${description.id}`,
        description,
        "Default work descriptions"
      );
    });
  };
  const selectRow = async (e, jobId) => {
    if (e.target.parentNode.className === "selected") {
      console.log("selected...");
    }
    setSelectedRow(jobId);
  };
  console.log("priceSheet:", priceSheet);
  return (
    <div className="group whole">
      <form onSubmit={handleSubmit} className="form">
        <h3>Price</h3>
        <div className="form-center">
          <div className="one-half">
            <Select
              name="priceSheet"
              labelText="Price Sheet"
              value={priceSheet || "default"}
              handleChange={(e) => setPriceSheet(e.target.value)}
              options={lists && lists.listPriceSheets}
            />
          </div>
          <div className="price-sheets whole">
            <h4>Residential Skylight Prices</h4>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: 40 }}>Id</th>
                  <th>Size</th>
                  <th>Domes Only</th>
                  <th>With Mill Frame</th>
                  <th>With Bronze Frame</th>
                  <th>Velux clear</th>
                  <th>Velux white</th>
                  <th>Reflash</th>
                </tr>
                {prices?.skylightPrices
                  .filter(
                    (price) => price.price_sheets_id === parseInt(priceSheet)
                  )
                  .map((listedPrice, index) => {
                    // console.log("listedPrice:", listedPrice);
                    return (
                      <tr
                        key={listedPrice.id}
                        // className="bananas"
                      >
                        <td
                        // className={selectedRow === listedPrice.id ? "selected" : ""}
                        // onClick={(e) => selectRow(e, listedPrice.id)}
                        >
                          {listedPrice.id}
                        </td>
                        <td>
                          <input
                            key={"size" + index}
                            value={listedPrice.size}
                            name={"size"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"domes_only" + index}
                            value={listedPrice.domes_only}
                            name={"domes_only"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"with_mill" + index}
                            value={listedPrice.with_mill}
                            name={"with_mill"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"with_bronze" + index}
                            value={listedPrice.with_bronze}
                            name={"with_bronze"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"velux_clear" + index}
                            value={listedPrice.velux_clear || "custom"}
                            name={"velux_clear"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"velux_white" + index}
                            value={listedPrice.velux_white || "custom"}
                            name={"velux_white"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                        <td>
                          <input
                            key={"reflash" + index}
                            value={listedPrice.reflash}
                            name={"reflash"}
                            onChange={(e) => handleInput(e)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <h4>Roofer Skylight Prices</h4>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: 40 }}>Id</th>
                  <th>Size</th>
                  <th>Domes Only</th>
                  <th>With Mill Frame</th>
                  <th>With Bronze Frame</th>
                  <th>Velux clear</th>
                  <th>Velux white</th>
                  <th>Reflash</th>
                </tr>
                {prices?.rooferSkylightPrices
                  .filter(
                    (price) => price.price_sheets_id === parseInt(priceSheet)
                  )
                  .map((listedPrice, index) => {
                    // console.log("listedPrice:", listedPrice);
                    return (
                      <tr
                        key={listedPrice.id}
                        // className="bananas"
                      >
                        <td
                        // className={selectedRow === listedPrice.id ? "selected" : ""}
                        // onClick={(e) => selectRow(e, listedPrice.id)}
                        >
                          {listedPrice.id}
                        </td>
                        <td>{listedPrice.size}</td>
                        <td>{listedPrice.domes_only}</td>
                        <td>{listedPrice.with_mill}</td>
                        <td>{listedPrice.with_bronze}</td>
                        <td>{listedPrice.velux_clear || "custom"}</td>
                        <td>{listedPrice.velux_white || "custom"}</td>
                        <td>{listedPrice.reflash}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <h4>Residential Sun Tunnel Prices</h4>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: 40 }}>Id</th>
                  <th>Diameter</th>
                  <th>Retail</th>
                  <th>Extension</th>
                  <th>Labor</th>
                </tr>
                {prices?.sunTunnelPrices
                  .filter(
                    (price) => price.price_sheets_id === parseInt(priceSheet)
                  )
                  .map((listedPrice, index) => {
                    // console.log("listedPrice:", listedPrice);
                    return (
                      <tr
                        key={listedPrice.id}
                        // className="bananas"
                      >
                        <td
                        // className={selectedRow === listedPrice.id ? "selected" : ""}
                        // onClick={(e) => selectRow(e, listedPrice.id)}
                        >
                          {listedPrice.id}
                        </td>
                        <td>{listedPrice.diameter}</td>
                        <td>{listedPrice.retail}</td>
                        <td>{listedPrice.extension}</td>
                        <td>{listedPrice.labor_first}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <h4>Roofer Sun Tunnel Prices</h4>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: 40 }}>Id</th>
                  <th>Diameter</th>
                  <th>Retail</th>
                  <th>Extension</th>
                  <th>Labor</th>
                </tr>
                {prices?.rooferSunTunnelPrices
                  .filter(
                    (price) => price.price_sheets_id === parseInt(priceSheet)
                  )
                  .map((listedPrice, index) => {
                    // console.log("listedPrice:", listedPrice);
                    return (
                      <tr
                        key={listedPrice.id}
                        // className="bananas"
                      >
                        <td
                        // className={selectedRow === listedPrice.id ? "selected" : ""}
                        // onClick={(e) => selectRow(e, listedPrice.id)}
                        >
                          {listedPrice.id}
                        </td>
                        <td>{listedPrice.diameter}</td>
                        <td>{listedPrice.retail}</td>
                        <td>{listedPrice.extension}</td>
                        <td>{listedPrice.labor_first}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <h4>Line Items</h4>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: 40 }}>Id</th>
                  <th>Item</th>
                  <th>Price</th>
                </tr>
                {prices?.extrasPrices
                  .filter(
                    (price) => price.price_sheets_id === parseInt(priceSheet)
                  )
                  .map((listedPrice, index) => {
                    // console.log("listedPrice:", listedPrice);
                    return (
                      <tr
                        key={listedPrice.id}
                        // className="bananas"
                      >
                        <td
                        // className={selectedRow === listedPrice.id ? "selected" : ""}
                        // onClick={(e) => selectRow(e, listedPrice.id)}
                        >
                          {listedPrice.id}
                        </td>
                        <td>{listedPrice.item}</td>
                        <td>{listedPrice.price}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
};
export default AdminPriceTables;
