import { useState } from "react";
import { Display1, Display, DisplayMoney } from "../workspace";
import { useAppContext } from "../../context/appContext";
import { moneyToNumber } from "../../utils/money";
import EditJobItem from "./EditJobItem";
import calculatePrices from "../../utils/calcJobItemTotal";
import ConfirmBox from "../ConfirmBox";

function JobItems() {
  const {
    jobItems,
    lists,
    isLoading,
    job,
    customer,
    options,
    prices,
    user,
    showConfirmBox,
    toggleConfirmBox,
    deleteRecord,
    getArrayRecord,
    indices,
  } = useAppContext();
  const { optionsIndex } = indices;
  const maxUserRolesId = 3;
  // const [index, setIndex] = useState(0)
  const [showEditForm, setShowEditForm] = useState(false);
  const [editItem, setEditItem] = useState({ quantity: 1, extras_ids: [] });
  const [editIndex, setEditIndex] = useState(0);
  const [deleteJobItemId, setDeleteJobItemId] = useState(() => {});

  const toggleEditItem = (index) => {
    // load and process job item info if already exists
    if (index >= 0) {
      setEditIndex(index);
      const newEditItem = { ...jobItems[index] };
      const use_custom_price = newEditItem.custom_price ? true : false;
      console.log("newEditItem.custom_price:", newEditItem.custom_price);
      console.log("first assigned use custom price:", use_custom_price);
      const newPrices = calculatePrices({
        newEditItem: { ...newEditItem, use_custom_price },
        job,
        customer,
        prices,
        lists,
      });
      setEditItem({ ...newEditItem, use_custom_price, ...newPrices });
    }
    // otherwise load a blank template
    else {
      setEditItem({
        quantity: 1,
        extras_ids: [],
        options_id: options[optionsIndex].id,
      });
    }
    setShowEditForm(!showEditForm);
  };
  const deleteJobItem = async () => {
    console.log("deleteJobItemId:", deleteJobItemId);
    console.log("option id:", options[optionsIndex]?.id);
    await deleteRecord(`/job-items/delete/${deleteJobItemId}`);
    // reload job items after deleting
    getArrayRecord(`/job-items/${options[optionsIndex]?.id}`, "jobItems");
  };
  const toggleDeleteBox = (id) => {
    setDeleteJobItemId(id);

    toggleConfirmBox({ confirmed: false });
  };
  // console.log("jobItems[editItem.index]:", jobItems[editItem.index])
  return (
    <div className="group whole">
      <form className="form form-center">
        <h3>Job Items</h3>
        <div name="job-items" className="subform">
          {jobItems.map((item, index) => {
            const {
              id,
              work_types_id,
              quantity,
              skylight_sizes_id,
              frame_types_id,
              top_glazing_id,
              bottom_glazing_id,
              glazing_materials_id,
              exact_width,
              exact_length,
              measurement_types_id,
              tube_depths_id,
              work_description,
              extras_ids,
              base_price,
              extras_price,
              custom_price,
            } = item;
            // convert money string to a number
            const basePrice = moneyToNumber(base_price);
            const extrasPrice = moneyToNumber(extras_price);
            const customPrice = moneyToNumber(custom_price);
            // build values
            const jobType = // if value present
              (work_types_id &&
                lists.listWorkTypes?.find((type) => {
                  return type.id === work_types_id;
                }).text) ||
              ""; // blank string if not found
            const size = // if value present (including 0)
              ((skylight_sizes_id || skylight_sizes_id === 0) &&
                lists.listSkylightSizes?.find((size) => {
                  return size.id === skylight_sizes_id;
                }).text) ||
              "";
            const measureType =
              (measurement_types_id &&
                lists.listMeasurementTypes?.find((measure) => {
                  return measure.id === measurement_types_id;
                })?.text) ||
              "";
            const exactSize = exact_width
              ? `${exact_width} x ${exact_length} ${measureType}`
              : "";
            const depth = // if value present (including 0)
              ((tube_depths_id || tube_depths_id === 0) &&
                lists.listTubeDepths?.find((depth) => {
                  return depth.id === tube_depths_id;
                }).text) ||
              "";
            const frame = // if value present (including 0)
              ((frame_types_id || frame_types_id === 0) &&
                lists.listFrameTypes?.find((frame) => {
                  return frame.id === frame_types_id;
                }).text) ||
              "";
            const glazingMaterial =
              (glazing_materials_id &&
                lists.listGlazingMaterials?.find((glazing) => {
                  return glazing.id === glazing_materials_id;
                }).text) ||
              "";
            const glazingColors = top_glazing_id // add if top glazing is present
              ? (top_glazing_id &&
                  lists.listGlazingColors?.find((glazing) => {
                    return glazing.id === top_glazing_id;
                  }).text) +
                (bottom_glazing_id // add if bottom glazing is present
                  ? "/" +
                    (bottom_glazing_id &&
                      lists.listGlazingColors?.find((glazing) => {
                        return glazing.id === bottom_glazing_id;
                      }).text)
                  : "")
              : "";
            const glazing = `${glazingColors} ${glazingMaterial}`;
            const extras = extras_ids
              ?.map((chosenExtra) => {
                return (
                  lists.listExtras?.find((listedExtra) => {
                    return listedExtra.id === chosenExtra;
                  })?.text || ""
                );
              })
              .toString();
            let unitPrice = customPrice // if custom price
              ? customPrice // use custom price
              : basePrice + extrasPrice; // else calculate price
            const ttlPrice = unitPrice * quantity;

            return (
              <div
                name={`job-item-${index}`}
                className="form-center sub-item"
                key={index}
              >
                {/* First Column */}
                <div className="one-third flex-parent">
                  {user?.user_roles_id <= maxUserRolesId && (
                    <>
                      <div className="one-half">
                        <button
                          name={`edit_job_item_${index}`}
                          className="btn btn-block"
                          type="button"
                          disabled={isLoading}
                          onClick={() => toggleEditItem(index)}
                        >
                          edit
                        </button>
                      </div>
                      <div className="one-half">
                        <button
                          name={`delete_job_item_${index}`}
                          className="btn btn-block red"
                          type="button"
                          disabled={isLoading}
                          onClick={() => toggleDeleteBox(id)}
                        >
                          delete
                        </button>
                      </div>
                    </>
                  )}

                  <Display name="job_type" label="job type" value={jobType} />
                  <Display name="skylight" value={`${size} ${frame}`} />
                  {work_types_id !== 5 ? (
                    // for all standard skylights
                    <>
                      <Display
                        name="exact_size"
                        label="exact size"
                        value={exactSize}
                      />
                      <Display name="glazing" value={glazing} />
                    </>
                  ) : (
                    // for sun tunnels
                    <>
                      <Display
                        name="tube_depth"
                        label="tube depth"
                        value={depth}
                      />
                      <Display
                        name="light_kit"
                        label="light kit"
                        value={extras}
                      />
                    </>
                  )}
                </div>
                {/* Second Column */}
                <div className="one-third flex-parent">
                  <DisplayMoney
                    name="base price"
                    value={customPrice || basePrice}
                  />
                  <DisplayMoney name="line items" value={extrasPrice} />
                  <DisplayMoney name="unit price" value={unitPrice} />
                  <Display name="quantity" value={quantity} />
                  <div className="bold whole">
                    <DisplayMoney name="total" value={ttlPrice} />
                  </div>
                </div>
                {/* Third Column */}
                <div className="one-third details">
                  <Display1
                    name="details"
                    value={work_description || ""}
                    handleChange={() => {}}
                  />
                </div>
              </div>
            );
          })}
          {/*  */}
        </div>
        {user?.user_roles_id <= maxUserRolesId && (
          <div className="">
            <button
              name="new_item"
              className="btn btn-block"
              type="button"
              onClick={toggleEditItem}
              disabled={isLoading}
            >
              New Item
            </button>
          </div>
        )}
      </form>
      {/* ----- Edit Job Items ------ */}
      <div
        className={
          showEditForm ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <EditJobItem
            editingItem={{ ...editItem, optionsIndex }}
            setEditItem={setEditItem}
            toggleEditItem={toggleEditItem}
            itemIndex={jobItems.length}
            index={editIndex}
          />
        </div>
      </div>
      {/* ----- Confirmation Box ------ */}
      <div
        className={
          showConfirmBox ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <ConfirmBox
            name="confirm_delete"
            confirmedFunction={deleteJobItem}
            message="Do you want to delete this job item?"
          />
        </div>
      </div>
    </div>
  );
}
export default JobItems;
