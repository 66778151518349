import { useAppContext } from "../../context/appContext";
import { TextArea } from "../workspace";

const AdminEmails = () => {
  const { defaults, handleChange, saveRecord, isLoading } = useAppContext();

  const handleTextChange = (index, newText) => {
    const baseObject = defaults;
    const name = "defaults.defaultEmails";
    const value = defaults.defaultEmails.map((message, i) => {
      return i === index ? { ...message, text: newText } : message;
    });
    handleChange({ baseObject, name, value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // iterate through all default messages and save each one
    defaults.defaultEmails.forEach((message) => {
      saveRecord(
        `/defaults/update-email-msg/${message.id}`,
        message,
        "Default email messages"
      );
    });
  };

  return (
    <div className="group whole">
      <form onSubmit={handleSubmit} className="form">
        <h3>Email Messages</h3>
        <div className="form-center">
          <button className="btn btn-block" type="submit" disabled={isLoading}>
            Save
          </button>
          <div className="whole">
            {defaults.defaultEmails?.map(({ type, text }, index) => {
              return (
                <TextArea
                  key={index}
                  name={type}
                  value={text}
                  handleChange={(e) => handleTextChange(index, e.target.value)}
                />
              );
            })}
          </div>
          <button className="btn btn-block" type="submit" disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
export default AdminEmails;
