import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext.js";
import SharedWrapper from "../assets/wrappers/SharedLayout.js";
import { LargeLogo } from "../components";
import { StackedDisplay } from "../components/workspace/index.js";

// http://localhost:3000/reports/1709
const Warranty = () => {
  const {
    address,
    appointments,
    loadMainRecords,
    getLists,
    defaults,
    getDefaults,
  } = useAppContext();

  const { jobId } = useParams();

  const { street_address } = address;

  const todaysDate = new Date();
  // find date that service was completed
  const dateString = appointments.filter(
    (appt) => appt.appt_statuses_id === 2 && appt.appt_types_id === 2
  )[0]?.appt_date;
  const serviceDate = new Date(dateString);
  const dateFormat = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // add 10 years for expiration date
  console.log(
    "serviceDate:",
    serviceDate.toLocaleDateString(undefined, dateFormat)
  );
  let expirationDate = new Date(serviceDate);
  expirationDate.setFullYear(serviceDate.getFullYear() + 10);
  console.log(
    "expirationDate:",
    expirationDate.toLocaleDateString(undefined, dateFormat)
  );
  // --- load lists and set default values first ---
  const initialize = async (jobId) => {
    if (Object.keys(defaults).length === 0) {
      console.log("ran once...");
      console.log("jobId:", jobId);
      await getDefaults();
      await loadMainRecords(jobId);
      await getLists();
    }
  };
  useEffect(() => {
    initialize(jobId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  return (
    <SharedWrapper>
      <main className="dashboard flex-parent warranty">
        {/* --- Header --- */}
        <div className="header whole flex-parent">
          <div className="one-half">
            <LargeLogo />
          </div>
          <div className="one-half">
            <div className="header-address">
              FreeLite Enterprises, Inc.
              <br />
              221 Garvon St
              <br />
              Garland, TX 75040
              <br />
              972-234-5990
            </div>
          </div>
        </div>
        {/* --- Body --- */}
        <div className="whole section">
          <div className="form-row display">To whom it may concern,</div>
          <div className="form-row display">
            FreeLite confirms that the flashing work completed at{" "}
            {street_address} on{" "}
            {serviceDate.toLocaleDateString(undefined, dateFormat)} is in
            compliance with our 10 year no leak warranty which is valid until{" "}
            {expirationDate.toLocaleDateString(undefined, dateFormat)}. Note
            that any modification made to the skylight curb or flashing voids
            our warranty.{" "}
          </div>
          <div className="form-row display">
            Warranty is void if anyone other than FreeLite services the
            skylights.
          </div>
        </div>

        {/* --- Signature / Date --- */}
        <div className="whole section flex-parent footer">
          <div className="one-half">
            <StackedDisplay
              name="FreeLite Enterprises Owner"
              value="Matt Charlson"
            />
          </div>
          <div className="one-half">
            <StackedDisplay
              name="Date"
              value={todaysDate.toLocaleDateString()}
            />
          </div>
        </div>
      </main>
    </SharedWrapper>
  );
};

export default Warranty;
