import { Input, Checkbox } from "../workspace";
import { useAppContext } from "../../context/appContext";

function Contact({ index }) {
  const { handleArrayChange, contacts } = useAppContext();
  const handleInput = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const baseArray = [...contacts];
    const baseName = "contacts";
    // console.log("baseName:", baseName)
    // console.log("baseArray:", baseArray)
    // console.log("index:", index)
    // console.log("baseObject:", baseArray[0])
    // console.log("property:", property)
    // console.log("value:", value)
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handlePhone = (e) => {
    const property = e.target.name;
    const rawValue = e.target.value;
    const baseArray = [...contacts];
    const baseName = "contacts";
    // remove nondigits and add dashes according to the pattern
    const phonePattern = /^(\d{3})(\d{3})(\d{4}).*/;
    const value = rawValue.replace(/\D/g, "").replace(phonePattern, "$1-$2-$3");
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handleCheckBox = (e) => {
    const property = e.target.name;
    const value = e.target.checked;
    const baseArray = [...contacts];
    const baseName = "contacts";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  return (
    <div className="form-center sub-item" key={index}>
      <div className="two-thirds">
        <Input
          type="text"
          name="contact_name"
          labelText="Contact Name"
          value={contacts[index].contact_name || ""}
          handleChange={(e) => handleInput(e)}
        />
      </div>
      <div className="one-third">
        <Input
          type="tel"
          name="phone"
          labelText="Phone"
          value={contacts[index].phone || ""}
          handleChange={(e) => handlePhone(e)}
        />
      </div>
      <div className="one-fourth checkbox">
        <Checkbox
          type="checkbox"
          name="is_active"
          labelText="Active"
          checked={contacts[index].is_active || false}
          handleChange={(e) => handleCheckBox(e)}
        />
      </div>
      <div className="three-fourths">
        <Input
          type="text"
          name="email"
          value={contacts[index].email || ""}
          handleChange={(e) => handleInput(e)}
        />
      </div>
      <div className="whole">
        <Input
          type="text"
          name="notes"
          value={contacts[index].notes || ""}
          handleChange={(e) => handleInput(e)}
        />
      </div>
    </div>
  );
}
export default Contact;
